import { memo, useContext } from "react";
import { HelpCircle, LogOut, Youtube, List, Send } from "react-feather";
import { Link, NavLink } from "react-router-dom";
import Optimizer from "src/features/Optimizer";
import star from "src/assets/icons/star.svg";
import heart from "src/assets/icons/heart.svg";
import { AppContext } from "src/shared/app-context";
import { ADMINS } from "../config";

import label from "src/assets/setters/collegi-label.svg";
import bg from "src/assets/client/bg-live.png";

function Header() {
  const { user, client } = useContext(AppContext);

  const signOut = async () => {
    await client?.auth.signOut();
  };

  const needOptimizer = ADMINS.includes(user!.id);
  const isVip = user?.type === "vip";
  const isTester = user?.type === "tester";

  return (
    <>
      {/* <div
        style={{
          backgroundImage: `url(${bg})`,
          height: "50vh",
        }}
        className="fixed left-0 top-0 w-full h-full bg-cover bg-center"
      >
        <div className="w-full h-full absolute bg-black/50"></div>
      </div> */}
      {/* <div className="absolute w-full h-32 bg-gradient-to-b from-bg via-bg/90 to-bg/0 left-0 -top-8 lg:-top-12 lg:h-40 xl:-top-16 xl:h-56"></div> */}
      {/* <div className="relative w-full flex mb-6 lg:mt-10 lg:mb-12 xl:mb-16 xl:mt-16 items-center"> */}
      {/* <div className="relative w-full h-24 bg-bg">
        <div className="flex justify-center items-center py-5">
          <img src={ipsenLogo} alt="ipsen" className="h-12" />
          <div className="h-12 w-px bg-white/30 mx-4"></div>
          <img src={eventLogo} alt="event" className="h-10" />
        </div>
      </div> */}

      <div className="relative w-full flex justify-center mb-6 items-center mt-2 lg:mt-8">
        {needOptimizer && <Optimizer />}
        <img src={label} alt="Logo" className="w-52 md:w-64" />
        {/* <div className="h-12 w-px bg-white/30 mx-4 md:block hidden"></div> */}
        {/* <img src={logo} alt="logo" className="w-32 ml-1 lg:-ml-4 lg:w-44" /> */}
        {/* <img src={logoFull} alt="logo" className="w-28 ml-1 lg:-ml-4 lg:w-40" /> */}
        <Link
          to="/profile"
          className="relative w-10 h-10 ring-2 ring-secondary rounded-full ml-auto"
        >
          <div
            className="bg-cover bg-center w-10 h-10 rounded-full"
            style={{ backgroundImage: `url(${user!.main_info!.photo})` }}
          />
          {isVip && (
            <img
              className="w-4 h-4 absolute -right-1 -bottom-1 rounded-full ring-2 ring-primary"
              src={star}
              alt="VIP"
            />
          )}
          {isTester && (
            <img
              className="w-4 h-4 absolute -right-1 -bottom-1 rounded-full ring-2 ring-primary"
              src={heart}
              alt="Tester"
            />
          )}
        </Link>
        <button title="Выйти" className="ml-4" onClick={signOut}>
          <LogOut className="text-white w-5" />
        </button>
      </div>

      <div className="fixed bottom-0 left-0 w-full h-16 z-30">
        <div className="absolute w-full h-full bg-white/70 backdrop-blur-sm"></div>
        <div className="max-w-lg mx-auto relative flex items-center justify-around h-full px-4">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "flex flex-col w-16 items-center text-primary"
                : "flex flex-col w-16 items-center text-black opacity-80"
            }
          >
            <Youtube className="w-6" />
            <span className="text-custo1m italic uppercase text-xs tracking-wide font-semibold mt-1">
              Live
            </span>
          </NavLink>
          {/* <NavLink
            to="shop"
            className={({ isActive }) =>
              isActive
                ? "flex flex-col w-16 items-center text-secondary"
                : "flex flex-col w-16 items-center text-white"
            }
          >
            <ShoppingBag className="w-5" />
            <span className="text-custom text-xs tracking-wide font-semibold mt-1">
              Магазин
            </span>
          </NavLink> */}
          <NavLink
            to="schedule"
            className={({ isActive }) =>
              isActive
                ? "flex flex-col w-16 items-center text-primary"
                : "flex flex-col w-16 items-center text-black opacity-80"
            }
          >
            <List className="w-5" />
            <span className="text-custo1m italic uppercase text-xs tracking-wide text-center font-semibold mt-1 leading-none">
              Программа
            </span>
          </NavLink>

          <a
            href="https://t.me/+Cut9U2gNqGRlMWIy"
            target="_blank"
            rel="noreferrer"
            className="flex flex-col w-16 items-center text-black opacity-80"
          >
            <Send className="w-5" />
            <span className="text-custo1m italic uppercase text-xs tracking-wide font-semibold mt-1">
              TG-чат
            </span>
          </a>
          <a
            href="https://t.me/kollegi_digital"
            target="_blank"
            rel="noreferrer"
            className="flex flex-col w-16 items-center text-black opacity-80"
          >
            <HelpCircle className="w-5" />
            <span className="text-custo1m italic uppercase text-xs tracking-wide font-semibold mt-1">
              Помощь
            </span>
          </a>
        </div>
      </div>
    </>
  );
}

const MemoizedHeader = memo(Header);
export default MemoizedHeader;
