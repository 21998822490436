import { times } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import { ArrowDown } from "react-feather";
import { schedule, ScheduleItem } from "./config";

const DAYS = ["30.01", "31.01", "01.02", "02.02", "03.02"] as const;
type Day = typeof DAYS[number];

const DAY_START = 7;
const DAY_END = 16.25;
const HOUR_HEIGHT = 250;

function Program({ live }: { live?: boolean }) {
  const [day, setDay] = useState<Day>(DAYS[4]);
  const scheduleForCurrentDay = useMemo(() => schedule[day] ?? [], [day]);
  const [stream, setStream] = useState<string>(schedule["03.02"][0].streamName);
  const streamItemsCount = scheduleForCurrentDay.length;

  useEffect(() => {
    if (scheduleForCurrentDay.find((item) => item.streamName === stream)) {
      return;
    }
    if (scheduleForCurrentDay.length === 0) {
      return;
    }
    setStream(scheduleForCurrentDay[0].streamName);
  }, [day, scheduleForCurrentDay, stream]);

  const streamItems = useMemo(() => {
    const items = scheduleForCurrentDay.find(
      (item) => item.streamName === stream
    )?.items;
    if (!items) {
      return [];
    }
    return items;
  }, [scheduleForCurrentDay, stream]);

  // Prepare for table
  const scheduleForCurrentDayWithGaps = scheduleForCurrentDay.map(
    (streamItem) => {
      const items: ScheduleItem[] = [];
      streamItem.items.forEach((item) => {
        items.push(item);
        if (item.rowSpan) {
          times(item.rowSpan - 1, () => {
            items.push({
              type: "none",
            });
          });
        }
      });

      return {
        ...streamItem,
        items,
      };
    }
  );

  let maxRows = 0;
  scheduleForCurrentDayWithGaps.forEach((streamItem) => {
    if (maxRows < streamItem.items.length) {
      maxRows = streamItem.items.length;
    }
  });

  return null;

  return (
    <div
      className={`container mx-auto ${live ? "mt-12" : "mt-12 md:mt-20 px-6"}`}
    >
      <h2 className="text-2xl font-semibold text-primary">
        Программа бизнес-сессий{" "}
        <sup className="text-secondary uppercase text-xs tracking-wider font-semibold">
          Время Московское
        </sup>
      </h2>
      <div className="flex mt-4 w-full overflow-auto">
        {DAYS.map((currentDay) => (
          <button
            key={currentDay}
            onClick={() => setDay(currentDay)}
            className={`${
              day === currentDay
                ? `border-primary text-primary ${
                    live ? "border-b-gray-100" : "border-b-white"
                  }`
                : "text-bg/50 border-b-transparent"
            } text-sm font-semibold border px-4 py-3 mr-2`}
          >
            {currentDay}
          </button>
        ))}
      </div>
      <hr className="mb-4 -mt-px" />

      <div className="w-full mb-12 flex flex-col">
        {scheduleForCurrentDay.length > 0 && (
          <div className="lg:hidden w-full relative">
            <select
              value={stream}
              onChange={(e) => setStream(e.target.value)}
              className={`${
                live ? "bg-white" : "bg-gray-100"
              } h-14 px-4 w-full outline-none appearance-none text-lg font-medium text-bg cursor-pointer`}
            >
              {scheduleForCurrentDay.map((streamItem) => (
                <option
                  key={streamItem.streamName}
                  value={streamItem.streamName}
                >
                  {streamItem.streamName}
                </option>
              ))}
            </select>
            <ArrowDown
              size={16}
              className="right-4 top-5 pointer-events-none absolute text-bg"
            />
          </div>
        )}

        {scheduleForCurrentDay.length === 0 && (
          <div className="flex items-center justify-center w-full h-64 bg-gray-100 px-8">
            <h3 className="text-xl font-medium text-bg text-center">
              Программа будет доступна позже
            </h3>
          </div>
        )}

        {scheduleForCurrentDay.length > 0 && (
          <div className="mt-4 flex flex-col lg:hidden">
            {streamItems
              .filter((t) => t.type !== "gap")
              .map((item) => {
                let typeClass = "";
                let timeClass = "";
                switch (item.type) {
                  case "lecture":
                    typeClass = "border-primary";
                    timeClass = "text-primary";
                    break;
                  case "break":
                    typeClass = "border-tertiary";
                    timeClass = "text-tertiary";
                    break;
                }

                const zoomLink =
                  item.zoomLink ??
                  scheduleForCurrentDay.find((t) => t.streamName === stream)
                    ?.zoomLink;

                return (
                  <div
                    key={item.time}
                    className={`${typeClass} border-l-4 bg-white p-2 text-base w-full mb-2`}
                  >
                    <p
                      className={`mb-2 text-xs right-2 bottom-1 xl:bottom-2 uppercase font-medium ${timeClass} tracking-wide`}
                    >
                      {item.time}
                    </p>
                    <h5 className="font-medium">{item.title}</h5>
                    {item.speaker && (
                      <p className={`${timeClass} mt-1 text-sm opacity-80`}>
                        {item.speaker}
                      </p>
                    )}
                    {item.type === "lecture" && zoomLink !== "LIVE" && (
                      <a
                        href={zoomLink}
                        style={{ backgroundColor: "#0e72ed" }}
                        className="px-3 py-1 text-white mt-1 inline-block rounded-full text-xs"
                      >
                        Подключиться
                      </a>
                    )}
                  </div>
                );
              })}
          </div>
        )}

        {scheduleForCurrentDay.length > 0 && (
          <div className="w-full hidden lg:flex">
            <table className="table-auto bg-gray-100">
              <thead className="border-b">
                <tr>
                  {scheduleForCurrentDay.map((streamItem) => (
                    <th
                      key={streamItem.streamName}
                      className="px-2 text-center"
                    >
                      {streamItem.streamName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {times(maxRows, (rowIndex) => (
                  <tr key={rowIndex}>
                    {scheduleForCurrentDayWithGaps.map((streamItem) => {
                      const item = streamItem.items[rowIndex];
                      let typeClass = "";
                      let timeClass = "";
                      switch (item.type) {
                        case "lecture":
                          typeClass = "border-l-primary";
                          timeClass = "text-primary";
                          break;
                        case "break":
                          typeClass = "border-l-tertiary";
                          timeClass = "text-tertiary";
                          break;
                      }
                      const cellClass =
                        item.type === "gap"
                          ? ""
                          : `bg-white p-2 text-sm border-2 relative ${
                              live ? "border-gray-100" : "border-gray-100"
                            }`;

                      const zoomLink = item.zoomLink ?? streamItem.zoomLink;

                      if (item && !item.desktopHidden && item.type !== "none") {
                        return (
                          <td
                            key={`${streamItem.streamName}-${rowIndex}-${item.type}`}
                            colSpan={item.colSpan ?? 1}
                            rowSpan={item.rowSpan ?? 1}
                            className={cellClass}
                          >
                            {item.type !== "gap" && (
                              <>
                                <div
                                  className={`absolute left-0 top-0 bottom-0 border-l-2 ${typeClass}`}
                                ></div>
                                <div key={item.time}>
                                  <p
                                    className={`text-xs right-2 bottom-1 xl:bottom-2 uppercase font-medium ${timeClass} tracking-wide`}
                                  >
                                    {item.time}
                                  </p>
                                  <h5 className="font-medium">{item.title}</h5>
                                  {item.speaker && (
                                    <p
                                      className={`${timeClass} text-xs opacity-80`}
                                    >
                                      {item.speaker}
                                    </p>
                                  )}
                                  {zoomLink === "LIVE" && (
                                    <p className="bg-secondary px-3 py-1 text-white mt-1 inline-block rounded-full text-xs">
                                      Трансляция
                                    </p>
                                  )}
                                  {item.type === "lecture" &&
                                    zoomLink !== undefined &&
                                    zoomLink !== "LIVE" && (
                                      <a
                                        href={zoomLink}
                                        style={{ backgroundColor: "#0e72ed" }}
                                        className="px-3 py-1 text-white mt-1 inline-block rounded-full text-xs"
                                      >
                                        Подключиться
                                      </a>
                                    )}
                                </div>
                              </>
                            )}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* {scheduleForCurrentDay.length > 0 && (
          <div className="w-full flex">
            {scheduleForCurrentDay.map((streamItem, index) => (
              <div
                key={streamItem.streamName}
                style={{ width: `${100 / streamItemsCount}%` }}
                className="hidden lg:block"
              >
                <div
                  className={`${
                    live ? "bg-gray-100" : "bg-white "
                  } flex pb-2 px-1 items-end lg:h-16 xl:h-12 sticky top-0 z-10 border-b border-x`}
                >
                  <h3 className="text-base font-semibold text-bg leading-none text-center w-full">
                    {streamItem.streamName}
                  </h3>
                </div>
                <div
                  style={{ height: (DAY_END - DAY_START) * HOUR_HEIGHT + 4 }}
                  className="relative bg-gray-100 py-1"
                >
                  {index === 0 && (
                    <>
                      {[7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((hour) => (
                        <div
                          key={hour}
                          style={{
                            transform: `translateY(${
                              (hour - DAY_START) * HOUR_HEIGHT
                            }px)`,
                          }}
                          className="absolute text-xs right-full w-10 h-4 text-right text-gray-600 pr-1 border-t pt-1"
                        >
                          {hour}:00
                        </div>
                      ))}
                    </>
                  )}
                  {streamItem.items.map((item) => {
                    if (!item.time) {
                      return null;
                    }
                    const start = item.time.split("-")[0];
                    const end = item.time.split("-")[1];

                    const startPosition =
                      parseInt(start.split(":")[0]) +
                      parseInt(start.split(":")[1]) / 60;
                    const endPosition =
                      parseInt(end.split(":")[0]) +
                      parseInt(end.split(":")[1]) / 60;

                    const translateY =
                      (startPosition - DAY_START) * HOUR_HEIGHT;
                    const height =
                      (endPosition - startPosition) * HOUR_HEIGHT - 4;

                    let typeClass = "";
                    let timeClass = "";
                    switch (item.type) {
                      case "lecture":
                        typeClass = "border-primary";
                        timeClass = "text-primary";
                        break;
                      case "break":
                        typeClass = "border-tertiary";
                        timeClass = "text-tertiary";
                        break;
                    }

                    return (
                      <div
                        key={item.time}
                        style={{
                          height,
                          transform: `translateY(${translateY}px)`,
                          width: `calc(100% - 4px)`,
                        }}
                        className={`${typeClass} border-l-4 absolute bg-white p-2 text-sm overflow-hidden`}
                      >
                        <p
                          className={`absolute text-xs right-2 bottom-1 xl:bottom-2 uppercase font-medium ${timeClass} tracking-wide`}
                        >
                          {item.time}
                        </p>
                        <h5 className="font-medium">{item.title}</h5>
                        {item.speaker && (
                          <p className={`${timeClass} text-xs opacity-80`}>
                            {item.speaker}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
}

const MemoizedProgram = memo(Program);
export { MemoizedProgram as Program };
