import produce from "immer";
import { nanoid } from "nanoid";
import { memo, useCallback, useContext, useRef, useState } from "react";
import { ArrowLeft, Camera, Edit2, LogOut } from "react-feather";
// @ts-ignore
import Compress from "react-image-file-resizer";
import ReactMarkdown from "react-markdown";
import { Link, Navigate } from "react-router-dom";
import { User } from "src/App";

import { AppContext } from "src/shared/app-context";
import Button from "src/shared/components/Button";
import Input from "src/shared/components/Input";

import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { Logger } from "src/shared/lib/logger";
import { REGIONS } from "src/shared/config/regions";
// import { WORK_IDS } from "src/shared/users";

import bg from "../assets/prof/moroccan-flower-dark.webp";
import { padStart, random } from "lodash";

type UserProfileType = User["main_info"];
type AvailableUserFields = keyof NonNullable<UserProfileType>;

const PROFILE_FIELDS = [
  {
    name: "fname",
    label: "Имя",
    placeholder: "Ваше имя",
  },
  // {
  //   name: "lname",
  //   label: "Фамилия",
  //   placeholder: "Ваша фамилия",
  // },
  {
    name: "ticket",
    label: "Номер билета",
    placeholder: "Укажите номер билета",
  },
  // {
  //   name: "email",
  //   label: "E-mail",
  //   placeholder: "Укажите электронную почту",
  // },
  // {
  //   name: "comment",
  //   label: "Телефон",
  //   placeholder: "Укажите ваш номер телефона",
  // },
] as const;

function ProfileScreen() {
  const analytics = getAnalytics();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { user, client, setUser } = useContext(AppContext);
  const [showPhotoHint, setShowPhotoHint] = useState(false);

  const signUp = useCallback(
    async (uData: any): Promise<false | any> => {
      const { fname, lname, phone, photo, division, comment, ticket } = uData;

      if (!client || !user) {
        return false;
      }

      const type = "basic";

      const userEntity = {
        id: user.id,
        type,
        main_info: {
          fname,
          lname,
          phone,
          division,
          photo,
          comment,
          ticket,
        },
      };

      await client.from("users").upsert(userEntity);

      const { data: newUserResponse } = await client
        .from("users")
        .select()
        .eq("id", user.id);

      return newUserResponse;
    },
    [client, user]
  );

  const [userData, setUserData] = useState<NonNullable<UserProfileType>>(
    user?.main_info
      ? {
          ...user.main_info,
        }
      : {
          fname: "",
          lname: "",
          phone: "",
          work_id: "",
          division: "",
          comment: "",
          ticket: "",
          photo: `/images/avatars/kollegi/${padStart(
            random(1, 104).toString(),
            3,
            "0"
          )}.png`,
        }
  );
  const [imageFile, setImageFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [redirect, setRedirect] = useState(false);

  const onImageChange = async (e: any) => {
    const file = e.target.files[0];

    // Compress file on client side
    Compress.imageFileResizer(
      file,
      120,
      120,
      "PNG",
      90,
      0,
      (uri: any) => {
        setImageFile(uri);
      },
      "blob"
    );

    // Update visual file
    let reader = new FileReader();
    reader.onload = (event: any) => {
      setUserField(event.target.result, "photo");
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const signOut = async () => {
    logEvent(analytics, "logout");
    await client?.auth.signOut();
  };

  async function checkTicket(ticket: string): Promise<false | string[]> {
    if (!client) {
      return false;
    }
    const { data } = await client
      .from("tickets")
      .select("devices")
      .eq("ticket", ticket);
    if (!data || data.length === 0) {
      return false;
    }
    return data[0].devices;
  }

  const saveUser = async (
    skip: boolean,
    skipExistingImage: boolean = false
  ) => {
    if (!client) {
      throw new Error("Client is empty");
    }

    setError(null);
    setLoading(true);
    const isTicketOk = await checkTicket(userData.ticket);
    if (isTicketOk === false) {
      setError("Номер билета не найден");
      setLoading(false);
      return;
    } else {
      localStorage.setItem("ticketId", userData.ticket);
      const uniqueDeviceId = localStorage.getItem("uniqueDeviceId") ?? nanoid();
      localStorage.setItem("uniqueDeviceId", uniqueDeviceId);

      const newDevices = [
        ...isTicketOk.filter((a) => a !== uniqueDeviceId),
        uniqueDeviceId,
      ].slice(-2); // only 2 devices allowed
      await client
        .from("tickets")
        .update({ devices: newDevices })
        .eq("ticket", userData.ticket);
      setLoading(false);
    }

    let photo = userData!.photo;
    if (imageFile === null && !skip && photo.includes("/images/avatars")) {
      setShowPhotoHint(true);
      return;
    }

    setLoading(true);

    // Update photo
    if (imageFile && !skipExistingImage) {
      const newPhotoPath = `${user!.id}/${nanoid(4)}.png`;

      // TODO: add remove old avatars before uploading new one
      await client.storage.from("avatars").upload(newPhotoPath, imageFile, {
        cacheControl: "3600",
        upsert: true,
      });

      logEvent(analytics, "upload_avatar");
      setUserProperties(analytics, { with_avatar: true });

      const { data } = client.storage
        .from("avatars")
        .getPublicUrl(newPhotoPath);
      photo = data.publicUrl;
    }

    if (skipExistingImage && photo.includes(";base64")) {
      photo = `/images/avatars/kollegi/${padStart(
        random(1, 104).toString(),
        3,
        "0"
      )}.png`;
    }

    const newUser = {
      ...userData,
      photo,
    };

    const data = await signUp(newUser);
    setLoading(false);

    setUserProperties(analytics, { type: data[0].type });
    logEvent(analytics, "save_profile");
    Logger.log(user!.id, "save_profile", newUser, client);

    setUser(data[0]);
    setTimeout(() => {
      setRedirect(true);
      // TODO: Onboarding will be here
    }, 300);
  };

  const setUserField = (value: string, key: AvailableUserFields) => {
    let filteredValue = value;
    if (key === "fname" || key === "lname") {
      filteredValue = value.replace(/[^а-яА-ЯёЁa-zA-Z\s]/g, "").slice(0, 140);
    }
    const newUserData = produce(userData, (draft) => {
      draft[key] = filteredValue;
    });
    setUserData(newUserData);
  };

  const isNewUser = !user?.main_info?.fname;

  const canSave =
    userData!.fname.trim().length > 1 && userData!.ticket?.trim().length > 5;

  if (showPhotoHint) {
    const uploadedPhoto = !userData["photo"].includes("/images/avatars");
    return (
      <>
        {redirect && <Navigate to="/" replace={true} />}

        <div className="container max-w-md mx-auto px-6 my-12 relative">
          <div className="bg-white items-center justify-center flex rounded-none">
            <div className="w-full p-6 text-xs flex relative">
              <div className="flex flex-col w-full">
                <label
                  className="flex items-center justify-center relative photo bg-center rounded-full w-20 h-20 mx-auto border-2 bg-cover cursor-pointer"
                  style={{
                    backgroundImage: !uploadedPhoto
                      ? undefined
                      : `url(${userData["photo"]})`,
                  }}
                >
                  <input
                    ref={inputFileRef}
                    type="file"
                    onChange={onImageChange}
                    className="hidden"
                    accept="image/*"
                  />
                  {!imageFile && <Camera />}
                </label>

                <h3 className="mt-4 text-xl text-center font-medium mb-4">
                  {userData["fname"]},<br />
                  добавите фото?
                </h3>
                <Button
                  loading={loading}
                  disabled={loading}
                  title={!imageFile ? "Выбрать фото" : "Сохранить"}
                  onClick={() => {
                    !imageFile
                      ? inputFileRef.current?.click()
                      : saveUser(false);
                  }}
                />
                <button
                  onClick={() => saveUser(true, true)}
                  className={`w-full mt-2 text-black inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium focus:outline-none underline underline-offset-2`}
                >
                  Пропустить шаг
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {redirect && <Navigate to="/" replace={true} />}

      {/* <LoginPasswordPopup /> */}

      <div className="relative container max-w-md mx-auto px-6 text-white pb-12 pt-2">
        <div className="flex flex-col space-y-4 bg-white p-6 pt-10 relative mt-20 rounded-none shadow-xl">
          <div className="absolute w-full h-full left-0 top-0">
            <button
              title="Выйти"
              className="-top-10 right-4 absolute p-2 -m-2"
              onClick={signOut}
            >
              <LogOut className="text-white w-5" />
            </button>
            {!isNewUser && (
              <Link to="/" className="-top-10 left-4 absolute p-2 -m-2">
                <ArrowLeft className="text-white w-5" />
              </Link>
            )}
            <label
              className="-top-10 left-1/2 -ml-10 absolute photo bg-gray-100 rounded-full w-20 h-20 mx-auto border bg-cover cursor-pointer"
              style={{
                backgroundImage: `url(${userData["photo"]})`,
              }}
            >
              <input
                type="file"
                onChange={onImageChange}
                className="hidden"
                accept="image/*"
              />
              <div className="absolute w-5 h-5 rounded-full ring-2 ring-white bg-primary flex items-center justify-center right-0 bottom-0 pointer-events-nones">
                <Edit2 className="w-3 h-3 text-white" />
              </div>
            </label>
          </div>

          {PROFILE_FIELDS.map((field) => (
            <Input
              dark
              type="text"
              placeholder={field.placeholder}
              key={field.name}
              name={field.name}
              label={field.label}
              value={userData[field.name] ?? ""}
              onChange={(value) => setUserField(value, field.name)}
            />
          ))}

          {/* checkbox */}
          {/* <div className="flex items-center justify-start relative">
            <button
              onClick={() => {
                localStorage.setItem(
                  "videoTab",
                  userData.division === "low-sound" ? "0" : "1"
                );
                setUserField(
                  userData.division === "low-sound" ? "" : "low-sound",
                  "division"
                );
              }}
              className="flex"
            >
              <div className="flex-shrink-0 w-5 h-5 border rounded-md border-gray-400/40 mr-2 flex items-center justify-center">
                {userData.division === "low-sound" && (
                  <div className="w-3 h-3 bg-secondary rounded"></div>
                )}
              </div>
              <span className="text-sm text-gray-600 text-left">
                Показывать мне версию для слабослышащих с сурдопереводом
              </span>
            </button>
          </div> */}

          {/* <div className="flex flex-col relative border border-gray-400/40 focus-within:ring-primary focus-within:border-primary px-3 py-2 focus-within:ring-1 text-gray-900">
            <label
              htmlFor="name"
              className={`block italic text-xs text-gray-600`}
            >
              Регион
            </label>
            <select
              value={userData.division}
              onChange={(e) => setUserField(e.target.value, "division")}
              className="pb-1 outline-none appearance-none sm:text-sm bg-white"
            >
              <option value="" disabled>
                Выберите из списка
              </option>
              {REGIONS.map((region) => {
                return (
                  <option key={region} value={region}>
                    {region}
                  </option>
                );
              })}
            </select>
          </div> */}
          {/* 
          <Input
            dark
            type="textarea"
            placeholder="В прямом эфире выберем 5 лучших и подарим подарки! Дайте волю креативу и любви к коллегам!"
            key="comment"
            name="ceomment"
            label="Пожелания коллегам (мин. 6 букв)"
            value={userData.comment ?? ""}
            onChange={(value) => setUserField(value, "comment")}
          /> */}

          {!canSave && (
            <p className="text-sm text-center text-black/50">
              Заполните поля анкеты,
              <br />
              чтобы сохранить профиль
            </p>
          )}
          <Button
            disabled={!canSave}
            loading={loading}
            title="Сохранить"
            onClick={() => saveUser(false)}
          />

          {error !== null && (
            <div className="bg-red-100 relative p-4 text-xs text-center rounded-md text-red-700">
              <p className="text-3xl mb-2">😱</p>
              <ReactMarkdown
                components={{
                  a: (props) => (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-600 underline underline-offset-1"
                      {...props}
                    />
                  ),
                }}
              >
                {error}
              </ReactMarkdown>
            </div>
          )}

          <p className="relative text-xs text-gray-600 text-center w-full">
            Нажимая на кнопку, вы соглашаетесь с{" "}
            <a
              className="underline underline-offset-1 text-primary"
              href="https://kollegi.digital/privatedata"
              target="_blank"
              rel="noreferrer"
            >
              политикой конфиденциальности
            </a>
          </p>
        </div>
        <div className="flex w-full items-center justify-center mt-4">
          <a
            href="https://t.me/kollegi_digital"
            target="_blank"
            rel="noreferrer"
            className="text-white text-sm font-medium underline underline-offset-4"
          >
            Чат поддержки
          </a>
        </div>
      </div>
    </>
  );
}

const MemoizedProfileScreen = memo(ProfileScreen);
export default MemoizedProfileScreen;
