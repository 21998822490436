import React, { memo } from "react";

interface ButtonProps {
  title: string;
  white?: boolean;
  small?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
}

function Button({
  title,
  white,
  loading,
  small,
  disabled,
  className,
  onClick,
}: ButtonProps) {
  return (
    <button
      disabled={loading || disabled}
      onClick={() => onClick()}
      className={`${disabled ? "opacity-60" : ""} ${
        white
          ? "text-black border-black hover:bg-black hover:text-white"
          : "text-white bg-primary border-primary"
      } ${className ? className : ""} ${
        small ? "px-4 py-2 h-10 text-sm" : "px-4 py-4 h-14 text-lg"
      } flex font-semibold items-center justify-center relative border-2 rounded-none`}
    >
      {loading ? (
        <div
          className={`${
            white ? "border-black" : "border-white"
          } w-5 h-5 rounded-full border-t-transparent border-2 animate-spin`}
        />
      ) : (
        <span className="relative">{title}</span>
      )}
    </button>
  );
}

const MemoizedButton = memo(Button);
export default MemoizedButton;
