import { memo, useContext, useState } from "react";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

import { AppContext } from "src/shared/app-context";
import Button from "src/shared/components/Button";
import { customAlphabet } from "nanoid";

import label from "../assets/setters/collegi-label.svg";
import logo from "../assets/prof/logo_256.png";

// import google from "../assets/icons/google.svg";
// import apple from "../assets/icons/apple.svg";
import Input from "src/shared/components/Input";
import { LogIn } from "react-feather";
import { Program } from "src/features/Program";
// import { MAIN_DOMAIN } from "src/shared/config";

const LOGIN_ERRORS: Record<string, string> = {
  "Invalid login credentials": "Неверный логин или пароль",
  "User not found": "Пользователь не найден",
  "User is disabled": "Пользователь заблокирован",
  "Too many unsuccessful login attempts. Please try again later.":
    "Слишком много неудачных попыток входа. Пожалуйста, попробуйте позже.",
};

// const LOGIN_PASS = "PRIDEX1523";

function AuthScreen() {
  const analytics = getAnalytics();
  const { client } = useContext(AppContext);

  // const [loginPass, setLoginPass] = useState("");

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function signInAsGuest() {
    if (client === null) {
      throw new Error("Client is null");
    }
    // if (loginPass !== LOGIN_PASS) {
    //   window.alert("Неверный пароль");
    //   return;
    // }

    setLoading(true);
    setError("");

    const existedCredentials = localStorage.getItem("login_credentials");
    if (existedCredentials) {
      const [existedLogin, existedPass] = existedCredentials.split(":");
      const { error } = await client.auth.signInWithPassword({
        email: `${existedLogin}@pitcha.cloud`,
        password: existedPass,
      });
      if (error) {
        localStorage.removeItem("login_credentials");
        setTimeout(() => signInAsGuest(), 1000);
      }
      return;
    }

    logEvent(analytics, "sign_in", { provider: "guest" });
    setUserProperties(analytics, { provider: "guest" });
    const login = customAlphabet("124567890QWRYUSDFGJLN", 8)();
    const password = customAlphabet("124567890QWRYUSDFGJLN", 8)();
    await client.auth.signUp({
      email: `${login}@pitcha.cloud`,
      password,
    });
    localStorage.setItem("login_credentials", `${login}:${password}`);

    setLoading(false);
  }

  async function signInWithCredentials() {
    if (client === null) {
      throw new Error("Client is null");
    }
    logEvent(analytics, "sign_in", { provider: "pass" });
    setUserProperties(analytics, { provider: "pass" });

    setLoading(true);
    setError("");

    // const data = await client.auth.signUp({
    //   email: `${login}@pitcha.cloud`,
    //   password,
    // });
    const { error } = await client.auth.signInWithPassword({
      email: `${login}@pitcha.cloud`,
      password,
    });
    setLoading(false);

    if (error) {
      const message = LOGIN_ERRORS[error.message]
        ? LOGIN_ERRORS[error.message]
        : error.message;
      setError(message);
    }
  }

  const canLogin = login.length > 4 && password.length > 5;
  const isGuestLogin = window.location.hash !== "#admin";
  const showGuestLogin = true; //window.location.hash === "#login";
  //

  return (
    <>
      <img
        src={label}
        alt="KOLLEGI"
        className="fixed bottom-10 right-10 w-64"
      />

      <div className="relative flex flex-col justify-center container min-h-screen mx-auto px-6 text-black py-12 max-w-screen-sm">
        <div className="flex flex-col bg-white p-8 py-10 shadow-xl">
          <p className="uppercase text-lg font-semibold text-primary tracking-wide mb-4">
            Добрый день, коллега!
          </p>

          <p className="mb-4">
            Конференция о бизнесе и для бизнеса. От предпринимателей
            и топ-менеджеров для всех, кто хочет быть, а не казаться инициатором
            изменений.
          </p>

          <div className="mt-4 flex flex-col w-full">
            {isGuestLogin ? (
              <>
                {/* <Input
                  name="password"
                  label="Пароль"
                  value={loginPass}
                  type="password"
                  onChange={(newPass) => setLoginPass(newPass.toUpperCase())}
                  className="mb-8"
                /> */}
                {showGuestLogin ? (
                  <Button
                    loading={loading}
                    title="Подключиться"
                    onClick={signInAsGuest}
                    className="w-full"
                  />
                ) : null}
              </>
            ) : (
              <>
                <Input
                  dark
                  name="login"
                  label="Логин"
                  value={login}
                  type="text"
                  className="mb-4"
                  onChange={setLogin}
                />
                <Input
                  dark
                  name="password"
                  label="Пароль"
                  value={password}
                  type="password"
                  className="mb-8"
                  onChange={setPassword}
                />
                <Button
                  disabled={!canLogin}
                  loading={loading}
                  title="Подключиться"
                  onClick={signInWithCredentials}
                />
                {error !== "" && (
                  <div className="bg-red-100 relative p-4 text-sm text-center text-red-700">
                    <p>{error}</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex w-full items-center justify-center mt-4">
            <a
              href="https://t.me/kollegi_digital"
              target="_blank"
              rel="noreferrer"
              className="text-sm font-medium underline underline-offset-4"
            >
              Чат поддержки
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedAuthScreen = memo(AuthScreen);
export default MemoizedAuthScreen;
