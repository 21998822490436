import { isEqual } from "lodash";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { Activity } from "react-feather";
import { AppContext } from "src/shared/app-context";
import { ROOM_ID } from "src/shared/config";

const CHAT_SHOW_LIMIT = 60;

function Optimizer() {
  const { client, blacklist } = useContext(AppContext);
  const [hasUpdates, setHasUpdates] = useState(false);
  const [activeState, setActiveState] = useState(false);

  const chatRef = useRef<any[]>([]);
  const chatTimeout = useRef<NodeJS.Timeout | null>(null);
  const needUpdate = useRef(false);

  useEffect(() => {
    const sync = async () => {
      if (!client || !activeState) {
        return;
      }

      if (needUpdate.current) {
        console.log("[ Optimizer ] Update");
        await client
          .from("rooms")
          .update({ chat: { items: chatRef.current } })
          .eq("id", ROOM_ID);

        needUpdate.current = false;
        setHasUpdates(false);
      } else {
        console.log("[ Optimizer ] No updates");
      }
    };
    const timer = setInterval(() => {
      sync();
    }, 1000);

    return () => clearInterval(timer);
  }, [client, activeState]);

  useEffect(() => {
    const syncer = async () => {
      if (chatTimeout.current) {
        clearTimeout(chatTimeout.current);
      }

      const { data, error } = await client!
        .from("chat")
        .select()
        .limit(CHAT_SHOW_LIMIT)
        .order("created_at", { ascending: false });

      if (error) {
        console.error("🔥 [ Optimizer ] Error", error);
      }

      const newData = data ?? [];
      const newChatData = newData.map((item) => {
        if (blacklist.includes(item.author_id)) {
          return { ...item, hidden: true };
        }
        return item;
      });
      if (!isEqual(newChatData, chatRef.current)) {
        chatRef.current = newChatData;
        needUpdate.current = true;
      }

      chatTimeout.current = setTimeout(() => syncer(), 1000);
    };

    if (activeState) {
      syncer();
    }

    // const chatListener = client!
    //   .channel("chat_changes")
    //   .on(
    //     "postgres_changes",
    //     { event: "INSERT", schema: "public", table: "chat" },
    //     (payload: any) => {
    //       console.log("NEW CHAT", payload);
    //       const newChat = [...chatRef.current, payload.new]
    //         .sort((a, b) => (a.id > b.id ? -1 : 1))
    //         .slice(0, CHAT_SHOW_LIMIT)
    //         .map((item) => {
    //           if (blacklist.includes(item.author_id)) {
    //             return { ...item, hidden: true };
    //           }
    //           return item;
    //         });
    //       console.log("[ Optimizer ] Detect new message", newChat.length);
    //       chatRef.current = newChat;
    //       needUpdate.current = true;
    //       setHasUpdates(true);
    //     }
    //   )
    //   .on(
    //     "postgres_changes",
    //     { event: "UPDATE", schema: "public", table: "chat" },
    //     (payload: any) => {
    //       if (chatRef.current.find((item) => item.id === payload.new.id)) {
    //         const newChat = chatRef.current.map((item) => {
    //           if (item.id === payload.new.id) {
    //             return payload.new;
    //           }
    //           return item;
    //         });
    //         chatRef.current = newChat;
    //         needUpdate.current = true;
    //         setHasUpdates(true);
    //       }
    //     }
    //   )
    //   .subscribe();

    // return () => {
    //   client!.removeChannel(chatListener);
    // };
  }, [blacklist, client, activeState]);

  return (
    <button
      onClick={() => setActiveState(!activeState)}
      className={`${
        hasUpdates ? "ring-yellow-500 text-yellow-500" : "ring-white text-white"
      } ${
        activeState ? "opacity-100" : "opacity-40"
      }  w-10 h-10 ring-2 flex items-center justify-center rounded-full mr-4`}
    >
      {hasUpdates && <Activity className="absolute w-6 animate-ping" />}
      <Activity className="w-6" />
    </button>
  );
}

const MemoizedOptimizer = memo(Optimizer);
export default MemoizedOptimizer;
