import { memo } from "react";
import { STICKERS } from "../config";

function Stickers({
  onChoose,
  show,
}: {
  show: boolean;
  onChoose: (sticker: string) => void;
}) {
  return (
    <div
      className={`${
        show
          ? "translate-y-0 opacity-100"
          : "pointer-events-none translate-y-24 opacity-0"
      } absolute w-full h-22 md:h-24 bottom-full left-0 transition duration-200 ease-in-out`}
    >
      <div className="absolute w-full h-full backdrop-blur-sm bg-white/60"></div>
      <div className="scrollbar w-full h-full relative overflow-x-auto overflow-y-hidden items-center flex pl-2 pt-2">
        {STICKERS.map((sticker) => (
          <button
            key={sticker}
            onClick={() => onChoose(`:${sticker}:`)}
            className={`${
              sticker.includes("custom_") ? "w-24 h-24" : "w-12 h-12"
            } relative mr-2 flex-shrink-0`}
          >
            <img alt={sticker} src={`/images/stickers/${sticker}.png`} />
          </button>
        ))}
      </div>
    </div>
  );
}

const MemoizedStickers = memo(Stickers);
export default MemoizedStickers;
