import { ScheduleItem } from "src/screens/Schedule";

// export interface ScheduleItem {
//   id: number;
//   time?: string;
//   speakers: {
//     speaker_name?: string;
//     speaker_photo?: string;
//     title?: string;
//     job?: string;
//     instagram?: string;
//     telegram?: string;
//   }[];
//   title: string;
//   highlights: string[];
//   gift: {
//     title: string;
//     url: string;
//   }[];
// }
const SPEAKERS: ScheduleItem[] = [
  {
    id: 1,
    time: "12:00",
    title: "Старт программы",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 2,
    time: "12:05",
    title:
      "Трендоруководство: какие тренды стоит начать внедрять сейчас и как к этому подступиться?",
    speakers: [
      {
        speaker_name: "Евгений Давыдов",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Davidoff.png",
        job: "SETTERS, SETTERS EDUCATION, re-feel, SETTERS Media, ПИАРХАБ, БУДУ, Эйч и Laoshi",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 3,
    time: "12:30",
    title: "Как управлять сложными людьми, если ты сам сложный человек",
    speakers: [
      {
        speaker_name: "Алексей Гиязов",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/giyazov.png",
        job: "Директор по маркетингу, дизайну и привлечению клиентов Альфа-Банка",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 4,
    time: "12:55",
    title: "«Как регулярно встряхивать бизнес, чтобы никто не жаловался»",
    speakers: [
      {
        speaker_name: "Даниил Шулейко",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Shuleiko.png",
        job: "«Яндекс»",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 5,
    time: "13:20",
    title: "Перерыв для общения с коллегами",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 6,
    time: "14:00",
    title: "Дискуссия от Дзена с предпринимателями",
    subtitle: "Коллеги-инфлюенсеры: меняем контент для бизнеса",
    speakers: [
      {
        speaker_name: "Ольга Стукалова",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Stukalova.png",
        job: "Руководитель группы развития рекламных продуктов в Дзене",
      },
      {
        speaker_name: "Татьяна Фомичева",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Fomicheva.png",
        job: "Основательница бренда STUDIO 29",
      },
      {
        speaker_name: "Анна Автайкина",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Avtaikina.png",
        job: "Основательница бренда The Cultt",
      },
      {
        speaker_name: "Максим Ганисевский",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Ganisevsky.png",
        job: "Основатель и CEO бренда ZNWR",
      },
      {
        speaker_name: "Анна Дубровина",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Dubrovina.png",
        job: "Бренд-директор Fashion Factory School",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 7,
    time: "14:30",
    title:
      "«Как драйвить себя и проект, когда всё пошло не по плану. Опыт re-feel»",
    speakers: [
      {
        speaker_name: "Алина Чичина",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Chichina.png",
        job: "re-feel, SETTERS, SETTERS EDUCATIONАнтон Беляев",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 8,
    time: "14:55",
    title: "Интервью о творчестве, изменениях и бизнесе",
    speakers: [
      {
        speaker_name: "Антон Беляев",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Belyaev.png",
        job: "Музыкант, автор песен, композитор и музыкальный продюсер, основатель и фронтмен Therr Maitz, создатель музыкального шоу Lab",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 9,
    time: "15:20",
    title: "Перерыв для общения с коллегами",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 10,
    time: "16:20",
    title: "Дискуссия от Дзена с инфлюенсерами",
    subtitle: "Стиль, бьюти, лайфстайл: как развивать свой блог в 2024",
    speakers: [
      {
        speaker_name: "Анна Рыбкина",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Rybkina.png",
        job: "Руководитель направления лайфстайл в Дзене",
      },
      {
        speaker_name: "Александра Жаркова",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/zharkova.png",
        job: "Соосновательница SETTERS, SETTERS EDUCATION, re-feel, издания SETTERS Media, партнёр ПИАРХАБ, БУДУ и Эйч",
      },
      {
        speaker_name: "Ксения Соловьева",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Solovieva.png",
        job: "Медиаменеджер, ex-главред Vogue и Tatler",
      },
      {
        speaker_name: "Кристина Манучарян",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Manucharyan.png",
        job: "Ex-редактор сайта Vogue и автор телеграм-канала Editors on vacation",
      },
      {
        speaker_name: "Настя Полетаева",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Poletaeva.png",
        job: "Эксперт по контенту и продакшну luxury e-commerce, автор YouTube-шоу БЕЗФИЛЬТРОВ, журналист",
      },
      {
        speaker_name: "Мария Червоткина",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Chervotkina.png",
        job: "Креативный директор, CEO SOLIDS Billiard Club & Bar",
      },
      {
        speaker_name: "Александр Рогов",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/rogov.png",
        job: "Эксперт поСтилист, эксперт моды, телеведущий, автор книги «Гид по Стилю», создатель бренда одежды ROGOV",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 11,
    time: "16:50",
    title:
      "«Аутентичный бизнес: как найти голос бренда и сделать его услышанным»",
    speakers: [
      {
        speaker_name: "Наталья Брянцева",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/Bryantseva.png",
        job: "Avgvst",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 12,
    time: "17:15",
    title:
      "«Искусство индивидуальности в бизнесе: как развивать личный бренд и что из этого может получиться»",
    speakers: [
      {
        speaker_name: "Александра Жаркова",
        speaker_photo:
          "https://anjosocqbazmeihiepkn.supabase.co/storage/v1/object/public/assets/speakers/zharkova.png",
        job: "SETTERS, SETTERS EDUCATION, re-feel, SETTERS Media, ПИАРХАБ, БУДУ и Эйч",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 13,
    time: "17:40",
    title: "А теперь КОЛЛЕГИ отдыхают",
    speakers: [],
    highlights: [],
    gift: [],
  },
];
const SPEAKERS_2: ScheduleItem[] = [
  {
    id: 100,
    time: "14:00",
    title: "Церемония награждения",
    speakers: [],
    highlights: [],
    gift: [],
  },
];

export { SPEAKERS, SPEAKERS_2 };
