import { nanoid } from "nanoid";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Check, Star, ThumbsDown, ThumbsUp } from "react-feather";
import { Room } from "src/screens/Live";
import { AppContext } from "src/shared/app-context";
import Button from "src/shared/components/Button";
import Input from "src/shared/components/Input";
import { ADMINS } from "src/shared/config";
import Cloud from "./Cloud";

import { getAnalytics, logEvent } from "firebase/analytics";
import { Logger } from "src/shared/lib/logger";

import poll from "src/assets/client/poll.png";
import cloud from "src/assets/client/cloud.png";
import winners from "src/assets/client/winners.png";
import { DIRTY_WORDS, DIRTY_WORDS1 } from "../config";

const SECONDS_PER_QUESTION = 30;

function PollMessage({
  data,
  disabled,
}: {
  data: Room["poll"];
  disabled?: boolean;
}) {
  const analytics = getAnalytics();
  const { user, client } = useContext(AppContext);
  const wordsWrapper = useRef<HTMLDivElement>(null);
  const [cloudWidth, setCloudWidth] = useState(100);
  const [myAnswer, setMyAnswer] = useState<number | string | null>(null);
  const [value, setValue] = useState("");
  const [rate, setRate] = useState(0);

  // Quiz part
  const [lastResponse, setLastResponse] = useState(false);
  const [remainTime, setRemainTime] = useState<number | null>(null);

  // Clear my answer when change poll
  useEffect(() => {
    setValue("");
    setMyAnswer(null);
    setRate(0);
    setLastResponse(false);
  }, [data?.id]);

  // Quiz timer
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (data && data.type === "quiz" && data.action === "start") {
      if (remainTime === null) {
        setRemainTime(SECONDS_PER_QUESTION);
      }
      timer = setTimeout(() => {
        if (remainTime! > 0) {
          setRemainTime(remainTime! - 1);
        }
      }, 1000);
    } else {
      setRemainTime(null);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [remainTime, data]);

  const sendAnswer = useCallback(
    async (answer: number | string) => {
      if (!client || !data || !user) {
        return;
      }
      logEvent(analytics, "poll_answer", { id: data.id, type: data.type });
      setMyAnswer(answer);

      const answerEntity = {
        author_id: user.id,
        poll_id: data.id,
        answer: answer,
        unique_id: ADMINS.includes(user.id)
          ? `${data.id}-${user.id}-${nanoid(4)}`
          : `${data.id}-${user.id}`,
      };
      await client.from("poll").insert(answerEntity);
      Logger.log(
        user.id,
        "answer",
        {
          poll_id: data.id,
          answer: answer,
        },
        client
      );
    },
    [analytics, client, data, user]
  );

  const sendQuizAnswer = useCallback(
    async (answer: number | string) => {
      if (!client || !data || !user) {
        return;
      }
      logEvent(analytics, "quiz_answer", { id: data.id, type: data.type });
      setMyAnswer(answer);

      const unique_id = ADMINS.includes(user.id)
        ? `${data.id}-${user.id}-${nanoid(4)}`
        : `${data.id}-${user.id}`;

      const answerEntity = {
        author_id: user.id,
        poll_id: data.id,
        answer: answer,
        unique_id,
      };
      const { error } = await client.from("poll").upsert(answerEntity);
      if (error) {
        return;
      }
      const response = await client!.rpc("quiz_answer", {
        answer_unique: unique_id,
      });
      setLastResponse((response.data as any) === "correct");
    },
    [analytics, client, data, user]
  );

  useEffect(() => {
    if (wordsWrapper.current) {
      setCloudWidth(wordsWrapper.current.clientWidth);
    }
  }, [data?.id, myAnswer]);

  if (!data) {
    return null;
  }

  switch (data.type) {
    case "words-cloud":
      const words = Object.keys(data.results)
        .filter(
          (w) =>
            DIRTY_WORDS.some((dw) => w.toLowerCase().includes(dw)) === false
        )
        .filter(
          (w) =>
            DIRTY_WORDS1.some((dw) => w.toLowerCase().includes(dw)) === false
        )
        .map((word) => {
          return {
            text: word.toUpperCase(),
            value: data.results[word],
          };
        });
      return (
        <div className="flex flex-col w-full text-black bg-gray-200">
          <div className="bg-white p-4 rounded-xl rounded-tl-none mx-4 mb-4">
            <div className="flex w-full">
              {/* <img src={cloud} className="w-8 h-8 mr-3" alt="poll" /> */}
              <div className="flex flex-col">
                <p className="text-xs font-medium text-primary">Опрос</p>
                <p className="text-sm">{data.name}</p>
              </div>
            </div>
            <div ref={wordsWrapper} className="flex flex-col space-y-3 mt-4">
              {!!myAnswer || disabled ? (
                <div
                  style={{ width: cloudWidth }}
                  className="bg-white rounded-xl w-full aspect-square overflow-hidden p-2"
                >
                  <Cloud
                    id={data.id}
                    data={words.length > 50 ? words.slice(0, 100) : words}
                  />
                </div>
              ) : (
                <>
                  <Input
                    dark
                    type="text"
                    name={`words-cloud-${data.id}`}
                    label="Введите свой ответ"
                    value={value}
                    onChange={(newValue) =>
                      setValue(
                        newValue.replace(/[^\w\sа-яА-ЯёЁ-]/g, "").slice(0, 20)
                      )
                    }
                  />
                  <Button
                    disabled={value.length < 2 || disabled}
                    title="Отправить"
                    small
                    onClick={() => sendAnswer(value.trim().toLowerCase())}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      );

    case "poll":
      const total = Object.values(data.results).reduce((a, b) => a + b, 0);
      return (
        <div className="flex flex-col w-full text-black bg-gray-200">
          <div className="bg-white p-4 rounded-xl rounded-tl-none mx-4 mb-4">
            <div className="flex w-full">
              {/* <img src={poll} className="w-8 h-8 mr-3" alt="poll" /> */}
              <div className="flex flex-col">
                <p className="text-xs font-medium text-primary">Опрос</p>
                <p className="text-sm">{data.name}</p>
              </div>
            </div>

            <div className="flex flex-col space-y-2 mt-4">
              {data.items.map((a) => {
                const result = data.results[a.id] ?? 0;
                const percent = Math.round((result / total) * 100) || 0;
                const isMyAnswer = value === `${a.id}`;
                return (
                  <button
                    key={a.id}
                    disabled={!!myAnswer || disabled}
                    onClick={() => setValue(`${a.id}`)}
                    className={`${
                      isMyAnswer ? "border-primary" : "border-white"
                    } overflow-hidden bg-bg/10 relative flex w-full items-center text-left border py-2 px-4 rounded-none`}
                  >
                    <div
                      style={{ width: `${percent}%` }}
                      className="absolute h-full left-0 bg-bg/20 transition-all duration-500"
                    ></div>
                    <span className="relative">{a.name}</span>
                    <span className="pl-2 flex items-center relative ml-auto text-xs text-black">
                      {isMyAnswer && (
                        <Check className="w-3 mr-1 flex-shrink-0" />
                      )}
                      {percent.toFixed(1)}%
                    </span>
                  </button>
                );
              })}
              {value.length === 0 ? (
                <p className="text-center text-sm h-10 mt-4 pt-2">
                  Выберите вариант ответа
                </p>
              ) : (
                !myAnswer &&
                !disabled && (
                  <Button
                    className="mt-4"
                    disabled={value.length === 0 || disabled}
                    title="Отправить"
                    small
                    onClick={() => sendAnswer(value)}
                  />
                )
              )}
            </div>
          </div>
        </div>
      );

    case "quiz":
      if (data.action === "winners") {
        const minePosition =
          (data.results as any).findIndex((u: any) => u.id === user!.id) + 1;
        const me = (data.results as any).find((u: any) => u.id === user!.id);

        return (
          <div className="flex flex-col w-full text-black bg-gray-200">
            <div className="bg-white p-4 rounded-xl rounded-tl-none mx-4 mb-4">
              <div className="w-full flex items-center">
                {/* <img src={winners} className="w-8 h-8 mr-4" alt="poll" /> */}
                <p className="text-base font-medium text-primary">Победители</p>
              </div>
              <div className="flex flex-col mt-3">
                {(data.results as any)
                  .slice(0, 10)
                  .map((u: any, index: number) => {
                    return (
                      <div
                        key={u.id}
                        className={`${
                          index === data.results.length - 1
                            ? ""
                            : "border-b border-white/20 mb-2"
                        } flex w-full items-center bg-bg/10 h-12 px-2 rounded-none`}
                      >
                        <p className="text-xs font-light text-secondary w-6 text-center flex-shrink-0">
                          {index + 1}
                        </p>
                        <div
                          style={{
                            backgroundImage: `url(${u.main_info.photo})`,
                          }}
                          className={`flex-shrink-0 w-8 h-8 bg-cover bg-center bg-white rounded-full ${
                            index === 1 ? "animation-delay-100" : ""
                          } ${index === 2 ? "animation-delay-200" : ""} ${
                            index < 3 ? "animate-bounce" : ""
                          }`}
                        />
                        <p className="ml-2 text-sm font-semibold">
                          {u.main_info.fname} {u.main_info.lname}
                        </p>
                        <p className="ml-auto text-xs text-primary w-10 pr-1 text-right font-light">
                          {u.points}
                        </p>
                      </div>
                    );
                  })}
              </div>
              {me && minePosition > 5 && (
                <div className="pt-4 pb-2 flex text-primary items-center justify-center border-t border-primary/20">
                  <p className="text-sm font-semibold">
                    Вы на {minePosition} месте и у вас {me.points} очков
                  </p>
                </div>
              )}
            </div>
          </div>
        );
      }
      return (
        <div className="flex flex-col w-full text-black bg-gray-200">
          <div className="bg-white p-4 rounded-xl rounded-tl-none mx-4 mb-4">
            <div className="flex items-start">
              <div className="text-custom flex-shrink-0 rounded-none relative text-sm font-light w-8 h-8 border border-primary text-primary flex items-center justify-center mr-3">
                {remainTime ?? 0}
                {data.action === "start" && (
                  <span className="absolute rounded-none animate-ping h-full w-full border border-primary"></span>
                )}
              </div>
              <div className="flex flex-col">
                <p className="text-xs font-medium text-primary">Вопрос</p>
                <p className="text-sm">{data.name}</p>
              </div>
            </div>
            <div className="flex flex-col space-y-2 mt-4">
              {data.items.map((a) => {
                const isMyAnswer = value === `${a.id}`;
                return (
                  <button
                    key={a.id}
                    disabled={!!myAnswer || disabled || data.action === "stop"}
                    onClick={() => setValue(`${a.id}`)}
                    className={`${
                      isMyAnswer ? "border-primary" : "border-white"
                    } overflow-hidden bg-bg/10 relative rounded-none flex w-full items-center text-left border py-2 px-4`}
                  >
                    <span className="relative">{a.name}</span>
                    <span className="pl-2 flex items-center relative ml-auto text-xs text-primary">
                      {isMyAnswer && (
                        <Check className="w-3 mr-1 flex-shrink-0" />
                      )}
                    </span>
                  </button>
                );
              })}
              {data.action === "stop" && lastResponse && (
                <div className="animate-show-in-from-top pt-2 pb-2 flex text-green-500 items-center justify-center">
                  <ThumbsUp className="mr-2 w-5 h-5" />
                  <p className="text-sm">Вы ответили верно!</p>
                </div>
              )}
              {data.action === "stop" && !lastResponse && myAnswer && (
                <div className="animate-show-in-from-top pt-2 pb-2 flex text-red-500 items-center justify-center">
                  <ThumbsDown className="mr-2 w-5 h-5" />
                  <p className="text-sm">Увы, ответ неправильный!</p>
                </div>
              )}
              {value.length === 0 ? (
                <p className="text-center text-sm h-10 mt-4 pt-2">
                  Выберите вариант ответа
                </p>
              ) : (
                !myAnswer &&
                !disabled &&
                data.action === "start" && (
                  <Button
                    disabled={value.length === 0 || disabled}
                    title="Ответить"
                    small
                    onClick={() => sendQuizAnswer(value)}
                  />
                )
              )}
            </div>
          </div>
        </div>
      );

    case "rate":
      if (!myAnswer && !disabled) {
        return (
          <div className="flex flex-col w-full text-black bg-gray-200">
            <div className="bg-white p-4 rounded-xl rounded-tl-none mx-4 mb-4">
              <p className="text-xs font-medium text-primary">Опрос</p>
              <p className="text-sm">{data.name}</p>

              <div className="flex flex-col space-y-2 mt-4">
                <div className="w-full flex space-x-2 mb-4">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => {
                      return (
                        <button
                          key={i}
                          onClick={() => setRate(i + 1)}
                          className=""
                        >
                          {rate >= i + 1 ? (
                            <Star className="w-8 h-8 text-primary" />
                          ) : (
                            <Star className="w-8 h-8 text-gray-400" />
                          )}
                        </button>
                      );
                    })}
                </div>
                <Button
                  small
                  title="Отправить"
                  disabled={rate === 0 || disabled}
                  onClick={() => sendAnswer(rate)}
                />
              </div>
            </div>
          </div>
        );
      }
      if (ADMINS.includes(user!.id)) {
        const totalRates = Object.values(data.results).reduce(
          (a, b) => a + b,
          0
        );
        let average = 0;
        if (totalRates > 0) {
          Object.keys(data.results).forEach((key) => {
            average += data.results[key] * parseInt(key);
          });
          average = average / totalRates;
        }

        return (
          <div className="flex flex-col w-full text-white bg-gray-200">
            <div className="bg-white p-4 rounded-xl rounded-tl-none mx-4 mb-4 text-black">
              <p className="text-xs font-medium text-primary">Опрос</p>
              <p className="text-sm">{data.name}</p>
              <div className="mt-4 flex w-full">
                <p className="text-3xl w-16 mr-4 text-primary flex-shrink-0">
                  {average.toFixed(2)}
                </p>
                <div className="flex flex-col space-y-2 w-full">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => {
                      const result = data.results[i + 1] ?? 0;
                      const percent =
                        Math.round((result / totalRates) * 100) || 0;
                      return (
                        <div
                          key={i}
                          className="flex items-center relative w-full text-xs bg-black/10 text-black px-2 py-1 rounded-md overflow-hidden"
                        >
                          <div
                            style={{ width: `${percent}%` }}
                            className="absolute h-full left-0 bg-primary/20 transition-all duration-500"
                          ></div>
                          <span className="text-sm relative text-primary">
                            {i + 1}
                          </span>
                          <span className="font-mono relative ml-2">
                            &times; {data.results[i + 1] ?? 0}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;

    case "race":
      return null;
  }
}

const MemoizedPollMessage = memo(PollMessage);
export default MemoizedPollMessage;
