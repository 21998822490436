export type ScheduleItem = {
  type: "lecture" | "break" | "gap" | "none";
  time?: string;
  title?: string;
  speaker?: string;
  rowSpan?: number;
  colSpan?: number;
  desktopHidden?: boolean;
  zoomLink?: string;
};

export type Schedule = {
  [key: string]: {
    streamName: string;
    zoomLink?: string;
    items: ScheduleItem[];
  }[];
};

export const schedule: Schedule = {
  "30.01": [
    {
      streamName: "Онкология",
      zoomLink: "https://zoom.us/j/92137022579",
      items: [
        {
          type: "gap",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "09:00-12:00",
          title: "Открытие: Пленарное заседание",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "LIVE",
          colSpan: 7,
        },
        {
          type: "break",
          time: "12:00-13:00",
          title: "Обед",
          colSpan: 4,
        },
        {
          type: "lecture",
          time: "13:00-13:15",
          title: "Training plan 2023",
          speaker: "Лариса Островская",
        },
        {
          type: "lecture",
          time: "13:15-14:00",
          title: "BI & Account Planning Update",
          speaker: "Мария Красникова, Рудольф Данелян",
        },
        {
          type: "lecture",
          time: "14:00-14:45",
          title: "SFE - Бонусная схема, KPI, MCCP, S&T",
          speaker: "Александр Беляев",
        },
        {
          type: "lecture",
          time: "14:45-15:15",
          title: "RAP - новый период, новые проекты (Виртуальный пациент)",
          speaker: "Мария Королева",
        },
        {
          type: "lecture",
          time: "15:15-16:15",
          title: "Ключевые проекты 2023",
        },
      ],
    },
    {
      streamName: "Редкие заболевания",
      zoomLink: "https://zoom.us/j/96296208059",
      items: [
        {
          type: "gap",
          rowSpan: 2,
        },
        {
          desktopHidden: true,
          type: "lecture",
          time: "09:00-12:00",
          title: "Открытие: Пленарное заседание",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "LIVE",
        },
        {
          desktopHidden: true,
          type: "break",
          time: "12:00-13:00",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "13:00-13:30",
          title: "Бизнес Результаты 2022. Бизнес цели 2023",
          speaker: "Екатерина Буркина",
        },
        {
          type: "lecture",
          time: "13:30-14:20",
          title: "Приоритеты медицинского отдела 2023 и взгляд в будущее",
          speaker: "Евгения Бухарова",
        },
        {
          type: "lecture",
          time: "14:20-15:00",
          title: "Роль и задачи команды MSL 2023",
          speaker: "Михаил Григорьев",
          rowSpan: 3,
        },
      ],
    },
    {
      streamName: "Неврология",
      zoomLink: "https://zoom.us/j/99818470834",
      items: [
        {
          type: "gap",
          rowSpan: 2,
        },
        {
          desktopHidden: true,
          type: "lecture",
          time: "09:00-12:00",
          title: "Открытие: Пленарное заседание",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "LIVE",
        },
        {
          desktopHidden: true,
          type: "break",
          time: "12:00-13:00",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "13:00-13:30",
          title: "Russia Neuroscience. Итоги 2022. Цели 2023.",
          speaker: "Виталий Терновых",
        },
        {
          type: "lecture",
          time: "13:30-14:30",
          title: "Market access",
          speaker: "Дмитрий Нечипоренко",
        },
        {
          type: "lecture",
          time: "14:30-15:00",
          title: "Приоритеты медицинского отдела 2023 и взгляд в будущее",
          speaker: "Евгения Бухарова",
        },
        {
          type: "lecture",
          time: "15:00-15:30",
          title: "Основные маркетинговые приоритеты 2023",
          speaker: "Тигран Торосян",
        },
        {
          type: "lecture",
          time: "15:30-16:00",
          title: "Коммерческая политика 2023",
          speaker: "Михаил Тарасов",
        },
      ],
    },
    {
      streamName: "Эстетика",
      zoomLink: "https://zoom.us/j/94189711763",
      items: [
        {
          type: "gap",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "09:00-12:00",
          title: "Открытие: Пленарное заседание",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "break",
          time: "12:00-13:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "13:00-13:10",
          title: "Открытие",
          speaker: "Елена Рязанцева",
        },
        {
          type: "lecture",
          time: "13:10-13:35",
          title: "Диспорт 3D",
          speaker: "Ринат Юсупов",
        },
        {
          type: "lecture",
          time: "13:35-14:00",
          title: "Медицинский взгляд на 3D",
          speaker: "Вера Баукина",
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "Диспорт - 3D оружие - в игре Царь Горы",
          rowSpan: 2,
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Микс",
      zoomLink: "https://zoom.us/j/93648114025",
      items: [
        {
          type: "lecture",
          time: "07:00-08:30",
          title: "Открытие Итоги 2022, Планы 2023. Вопросы и ответы",
          zoomLink: "https://zoom.us/j/92137022579",
          colSpan: 2,
        },
        {
          type: "break",
          time: "08:30-09:00",
          title: "Кофе-пауза",
          colSpan: 2,
        },
        {
          type: "lecture",
          time: "09:00-12:00",
          title: "Открытие: Пленарное заседание",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "gap",
        },
        {
          type: "lecture",
          time: "12:15-13:15",
          title: "Кабо Казахстан",
          zoomLink: "https://zoom.us/j/93648114025",
        },
        {
          type: "lecture",
          time: "13:40-14:10",
          title: "Новое в комплаенс политике и этике ведения бизнеса",
          speaker: "Ярослав Дроздов",
          zoomLink: "https://zoom.us/j/93648114025",
          colSpan: 2,
        },
        {
          type: "lecture",
          time: "14:15-14:45",
          title: "Кабо Казахстан. Вопросы и ответы.",
          zoomLink: "https://zoom.us/j/93648114025",
          rowSpan: 3,
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Эстетика",
      zoomLink: "https://zoom.us/j/94189711763",
      items: [
        {
          type: "lecture",
          time: "07:00-08:30",
          title: "Открытие Итоги 2022, Планы 2023. Вопросы и ответы",
          zoomLink: "https://zoom.us/j/92137022579",
          desktopHidden: true,
        },
        {
          type: "break",
          time: "08:30-09:00",
          title: "Кофе-пауза",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "09:00-12:00",
          title: "Открытие: Пленарное заседание",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "gap",
        },
        {
          type: "lecture",
          time: "13:00-14:00",
          title: "Диспорт Маркетинг-Медикал",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/94189711763",
        },
        {
          type: "lecture",
          time: "13:40-14:10",
          title: "Новое в комплаенс политике и этике ведения бизнеса",
          speaker: "Ярослав Дроздов",
          zoomLink: "https://zoom.us/j/93648114025",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "Диспорт - 3D оружие - в War Games",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/94189711763",
          rowSpan: 3,
        },
      ],
    },
    {
      streamName: "Регуляторный отдел",
      zoomLink: "https://us06web.zoom.us/j/84559827515",
      items: [
        {
          type: "gap",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "09:00-12:00",
          title: "Открытие: Пленарное заседание",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          desktopHidden: true,
        },
        {
          type: "break",
          time: "12:00-13:00",
          title: "Обед",
        },
        {
          type: "gap",
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title:
            "Гармонизация Евразэс: статус, план 2023",
          speaker: "RA team Russia and KZ",
          rowSpan: 4,
        },
      ],
    },
  ],
  "31.01": [
    {
      streamName: "Онкология",
      zoomLink: "https://zoom.us/j/99763372794",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title:
            "Онкология будущего: достижения, приоритеты и цели 2023. Diphereline 22,5, Tazverik",
          speaker: "Мария Королева",
        },
        {
          type: "lecture",
          time: "10:00-11:30",
          title: "Обзор рынков мПКР, РПЖ, ДРЩЖ",
          speaker: "Ирина Рычкова / Надежда Рубцова",
          rowSpan: 5,
        },
        {
          type: "lecture",
          time: "11:30-11:50",
          title: "Юридическая сессия",
          speaker: "Диана Тихомирова",
          zoomLink: "https://zoom.us/j/99763372794",
          colSpan: 7,
        },
        {
          type: "break",
          time: "11:50-12:30",
          title: "Обед",
          colSpan: 5,
        },
        {
          type: "lecture",
          time: "12:30-16:00",
          title: "Team building",
          zoomLink: "https://zoom.us/j/91517984295",
          colSpan: 7,
        },
      ],
    },
    {
      streamName: "Редкие заболевания",
      zoomLink: "https://zoom.us/j/91099723161",
      items: [
        {
          type: "lecture",
          time: "09:00-09:45",
          title:
            "Маркетинговая аналитика: реп чек, результаты бренд аудита, маркетинговые метрики",
          speaker: "Олег Ковальцов",
        },
        {
          type: "lecture",
          time: "09:45-10:30",
          title: "BI & Account Planning Update",
          speaker: "Мария Красникова / Лариса Островская",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "10:45-11:30",
          title: "RAP: новые цели 2023",
          speaker: "Екатерина Зайченко / Олег Ковальцов",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "11:30-11:50",
          title: "Юридическая сессия",
          speaker: "Диана Тихомирова",
          zoomLink: "https://zoom.us/j/99763372794",
          desktopHidden: true,
        },
        {
          type: "break",
          time: "11:50-12:30",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "12:30-16:00",
          title: "Team building",
          zoomLink: "https://zoom.us/j/91517984295",
          desktopHidden: true,
        },
      ],
    },
    {
      streamName: "Неврология",
      zoomLink: "https://zoom.us/j/96322388642",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "Взрослая спастичность. Marketing",
          speaker: "Тигран Торосян",
        },
        {
          type: "lecture",
          time: "10:00-11:00",
          title: "Взрослая спастичность. Medical team",
        },
        {
          type: "lecture",
          time: "11:00-11:30",
          title: "Взрослая спастичность. Market Access (Q&A)",
          speaker: "Дмитрий Нечипоренко",
          rowSpan: 4,
        },
        {
          type: "lecture",
          time: "11:30-11:50",
          title: "Юридическая сессия",
          speaker: "Диана Тихомирова",
          zoomLink: "https://zoom.us/j/99763372794",
          desktopHidden: true,
        },
        {
          type: "break",
          time: "11:50-12:30",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "12:30-16:00",
          title: "Team building",
          zoomLink: "https://zoom.us/j/91517984295",
          desktopHidden: true,
        },
      ],
    },
    {
      streamName: "Эстетика",
      zoomLink: "https://zoom.us/j/98635833870",
      items: [
        {
          type: "lecture",
          time: "09:00-09:30",
          title: "Best Practice Диспорт",
        },
        {
          type: "lecture",
          time: "09:30-10:00",
          title: "NCTF - новый взгляд на доказанную эффективность",
          speaker: "Вера Баукина",
        },
        {
          type: "lecture",
          time: "10:00-10:20",
          title: "NCTF 135 HA + М-НА 10 - больше, чем два",
          speaker: "Софья Кононенко",
        },
        {
          type: "lecture",
          time: "10:20-10:30",
          title: "Опыт клиники №1 по NCTF и М-НА 10",
          speaker: "Наталья Баниене",
        },
        {
          type: "lecture",
          time: "10:30-10:40",
          title: "История развития тренера и клиники (А. Купич)",
        },
        {
          type: "lecture",
          time: "10:40-11:30",
          title: "Мезо Царь Горы",
        },
        {
          type: "lecture",
          time: "11:30-11:50",
          title: "Юридическая сессия",
          speaker: "Диана Тихомирова",
          zoomLink: "https://zoom.us/j/99763372794",
          desktopHidden: true,
        },
        {
          type: "break",
          time: "11:50-12:30",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "12:30-16:00",
          title: "Team building",
          zoomLink: "https://zoom.us/j/91517984295",
          desktopHidden: true,
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Микс",
      zoomLink: "https://zoom.us/j/95422265947",
      items: [
        {
          type: "lecture",
          time: "07:00-09:00",
          title: "Диспорт неврология Казахстан",
          zoomLink: "https://zoom.us/j/95422265947",
        },
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "Взрослая спастичность. Marketing",
          speaker: "Тигран Торосян, ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/96322388642",
        },
        {
          type: "lecture",
          time: "10:00-11:00",
          title: "Взрослая спастичность. Medical team",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/96322388642",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "11:00-11:30",
          title: "Взрослая спастичность. Market Access (Q&A)",
          speaker: "Дмитрий Нечипоренко, ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          rowSpan: 2,
          zoomLink: "https://zoom.us/j/96322388642",
        },
        {
          type: "lecture",
          time: "11:30-11:50",
          title: "Юридическая сессия",
          speaker: "Диана Тихомирова",
          desktopHidden: true,
          zoomLink: "https://zoom.us/j/99763372794",
        },
        {
          type: "break",
          time: "11:50-12:30",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "12:30-16:00",
          title: "Team building",
          desktopHidden: true,
          zoomLink: "https://zoom.us/j/91517984295",
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Эстетика",
      items: [
        {
          type: "lecture",
          time: "07:00-11:00",
          title: "Визиты в клиники",
          rowSpan: 3,
        },
        {
          type: "break",
          time: "11:00-12:00",
          title: "Обед",
        },
        {
          type: "gap",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "11:30-11:50",
          title: "Юридическая сессия",
          speaker: "Диана Тихомирова",
          zoomLink: "https://zoom.us/j/99763372794",
          desktopHidden: true,
        },
        {
          type: "gap",
        },
        {
          type: "lecture",
          time: "12:30-16:00",
          title: "Team building",
          desktopHidden: true,
          zoomLink: "https://zoom.us/j/91517984295",
        },
      ],
    },
    {
      streamName: "Регуляторный отдел",
      zoomLink: "https://us06web.zoom.us/j/82604264810",
      items: [
        {
          type: "lecture",
          time: "09:00-10:30",
          title: "Audit Status RU and KZ",
          speaker: "RA team Russia and KZ",
          rowSpan: 3,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "10:45-11:30",
          title: "Preparation of the KZ Audit",
          speaker: "RA team Russia and KZ",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "11:30-11:50",
          title: "Юридическая сессия",
          speaker: "Диана Тихомирова",
          zoomLink: "https://zoom.us/j/99763372794",
          desktopHidden: true,
        },
        {
          type: "break",
          time: "11:50-12:30",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "12:30-16:00",
          title: "Team building",
          desktopHidden: true,
          zoomLink: "https://zoom.us/j/91517984295",
        },
      ],
    },
  ],
  "01.02": [
    {
      streamName: "Онкология",
      zoomLink: "https://zoom.us/j/92131327038",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "мПКР. Медицинские данные. 2+L Кабометикс",
          speaker: "Артем Тукач",
        },
        {
          type: "lecture",
          time: "10:00-10:30",
          title: "мПКР. Медицинские данные. Кабометикс+ниволумаб",
          speaker: "Артем Тукач",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
          colSpan: 2,
        },
        {
          type: "lecture",
          time: "10:45-12:45",
          title: "Кабометикс в мПКР - стратегия развития бренда",
          speaker: "Ирина Рычкова",
        },
        {
          type: "lecture",
          time: "12:45-13:15",
          title: "Best practice. Кабометикс",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "13:15-14:00",
          title: "Обед",
          colSpan: 4,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "ЦЕРЕМОНИЯ НАГРАЖДЕНИЯ TOP PERFORMER 2022",
          zoomLink: "LIVE",
          colSpan: 7,
        },
        {
          type: "gap",
        },
      ],
    },
    {
      streamName: "Редкие заболевания",
      zoomLink: "https://zoom.us/j/99807063301",
      items: [
        {
          type: "lecture",
          time: "09:00-09:45",
          title: "Training plan 2023",
          speaker: "Лариса Островская",
        },
        {
          type: "lecture",
          time: "09:45-10:30",
          title: "Сессия SFE",
          speaker: "Александр Беляев, Рудольф Данелян",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "10:45-11:15",
          title: "Коммерческая политика 2023",
          speaker: "Михаил Тарасов",
        },
        {
          type: "lecture",
          time: "11:15-12:30",
          title: "Новое в комплаенс политике и в этике ведения бизнеса",
          speaker: "Ярослав Дроздов",
        },
        {
          type: "lecture",
          time: "12:30-13:00",
          title: "Q&A",
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "ЦЕРЕМОНИЯ НАГРАЖДЕНИЯ TOP PERFORMER 2022",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "gap",
        },
      ],
    },
    {
      streamName: "Неврология",
      zoomLink: "https://zoom.us/j/95263342725",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "Детская спастичность. Marketing",
          speaker: "Тигран Торосян",
        },
        {
          type: "lecture",
          time: "10:00-11:00",
          title:
            "Детская спастичность. Medical team (2 rooms in ZOOM by regional teams)",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "11:00-11:15",
          title: "Кофе-пауза",
          colSpan: 2,
        },
        {
          type: "lecture",
          time: "11:15-11:45",
          title: "Детская спастичность. Market Access (Q&A)",
          speaker: "Дмитрий Нечипоренко",
        },
        {
          type: "lecture",
          time: "11:45-12:15",
          title: "SFE - Incentive Scheme, KPI goals, MCCP, S&T",
          speaker: "Александр Беляев",
        },
        {
          type: "lecture",
          time: "12:15-13:00",
          title: "Навигатор для врача-инжектора",
          speaker: "Елена Решетник / Чулпан Линкевич",
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "ЦЕРЕМОНИЯ НАГРАЖДЕНИЯ TOP PERFORMER 2022",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "gap",
        },
      ],
    },
    {
      streamName: "Эстетика",
      zoomLink: "https://zoom.us/j/97456481262",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "BI & Account Planning Update",
          speaker: "Мария Красникова SFE Диана Курбанова",
        },
        {
          type: "lecture",
          time: "10:00-10:40",
          title: "ART FILLER - время вдохновлять!",
          speaker: "Вера Баукина",
        },
        {
          type: "lecture",
          time: "10:40-11:00",
          title: "ART FILLER - выбирай лучшее!",
          speaker: "Софья Кононенко",
        },
        {
          type: "break",
          time: "11:00-11:15",
          title: "Кофе-пауза",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "11:15-11:30",
          title: "Отстройка от конкурентов через реологию филлера",
          speaker: "Максим Нехороших, Эльвина Юсупова",
        },
        {
          type: "lecture",
          time: "11:30-11:40",
          title: "История развития одной клиники",
          speaker:
            "клиника «Медисанс» - Снежана Взорова, клиника Ренью – Галина Маркевич",
        },
        {
          type: "lecture",
          time: "11:40-13:00",
          title: "ART FILLER Царь Горы",
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "ЦЕРЕМОНИЯ НАГРАЖДЕНИЯ TOP PERFORMER 2022",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "gap",
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Микс",
      zoomLink: "https://zoom.us/j/98025788783",
      items: [
        {
          type: "lecture",
          time: "06:00-09:00",
          title: "Административная активность (авансовые отчеты 01.2023)",
          zoomLink: "https://zoom.us/j/98025788783",
        },
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "Детская спастичность. Marketing",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/95263342725",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "10:00-11:00",
          title: "Детская спастичность. Medical team",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/95263342725",
        },
        {
          type: "break",
          time: "11:00-11:15",
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "11:15-11:45",
          title: "Детская спастичность. Market access (Q&A)",
          speaker: "Дмитрий Нечипоренко, ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/95263342725",
        },
        {
          type: "break",
          time: "11:45-12:45",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "12:45-13:15",
          title: "Best practice Кабометикс",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/92131327038",
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "ЦЕРЕМОНИЯ НАГРАЖДЕНИЯ TOP PERFORMER 2022",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "gap",
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Эстетика",
      zoomLink: "https://zoom.us/j/97233494790",
      items: [
        {
          type: "lecture",
          time: "06:00-09:00",
          title: "Диспорт эстетика Казахстан",
        },
        {
          type: "break",
          time: "09:00-10:00",
          title: "Обед",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "10:00-11:00",
          title: "Административная активность (авансовые отчеты 01.2023)",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "11:00-10:15",
          title: "Кофе-пауза",
        },
        {
          type: "gap",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "ЦЕРЕМОНИЯ НАГРАЖДЕНИЯ TOP PERFORMER 2022",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "gap",
        },
      ],
    },
    {
      streamName: "Отдел по бизнес-эффективности",
      zoomLink: "https://us06web.zoom.us/j/83358044618",
      items: [
        {
          type: "gap",
          rowSpan: 8,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "ЦЕРЕМОНИЯ НАГРАЖДЕНИЯ TOP PERFORMER 2022",
          zoomLink: "LIVE",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "16:00-18:00",
          title: "Сессия отдела по Бизнес-эффективности",
        },
      ],
    },
  ],
  "02.02": [
    {
      streamName: "Онкология",
      zoomLink: "https://zoom.us/j/92404290894",
      items: [
        {
          type: "lecture",
          time: "09:00-09:30",
          title: "ДРЩЖ. Медицинская часть",
          speaker: "Артем Тукач",
        },
        {
          type: "lecture",
          time: "09:30-10.30",
          title: "ДРЩЖ. Маркетинговая стратегия",
          speaker: "Надежда Рубцова",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
          colSpan: 2,
        },
        {
          type: "lecture",
          time: "10:45-11:15",
          title: "РПЖ. Медицинские данные",
          speaker: "Наталья Ткаченко",
        },
        {
          type: "lecture",
          time: "11:15-12:15",
          title: "РПЖ - маркетинговая стратегия",
          speaker: "Надежда Рубцова",
        },
        {
          type: "lecture",
          time: "12:15-12:45",
          title: "Best practice. Диферелин",
        },
        {
          type: "lecture",
          time: "12:45-13:15",
          title: "Общий визитный план. Новое в campaign book",
          speaker: "Ирина Рычкова",
        },
        {
          type: "break",
          time: "13:15-14:00",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "14:00-14:45",
          title: "Коммерческая политика. Проект по Диферелину",
          speaker: "Михаил Тарасов",
        },
        {
          type: "lecture",
          time: "14:45-15:30",
          title: "Market access мПКР, РПЖ",
          speaker: "Дмитрий Нечипоренко",
        },
        {
          type: "lecture",
          time: "15:30-16:15",
          title: "MSL activities 2023",
          speaker: "Наталья Ткаченко",
        },
      ],
    },
    {
      streamName: "Редкие заболевания",
      zoomLink: "https://zoom.us/j/99939690894",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title:
            "Мнение эксперта: Хирургический этап оказания МП пациентам с НЭО",
          speaker: "Иванова А.К., Диникин",
        },
        {
          type: "lecture",
          time: "10:00-10:30",
          title: "MA Стратегия Соматулин НЭО",
          speaker: "Дмитрий Нечипоренко",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "10:45-12:00",
          title: "Стратегия продвижения: фокус НЭО. Digital инициативы 2023",
          speaker: "Олег Ковальцов, Рудольф Данелян",
        },
        {
          type: "lecture",
          time: "12:00-12:30",
          title:
            "Медицинская аргументация: результаты исследования Harrow 2022",
          speaker: "Екатерина Ковтун",
        },
        {
          type: "lecture",
          time: "12:30-13:00",
          title:
            "Медицинская аргументация в маркетинговой стратегии (Фокус на таргетной терапии НЭО)",
          speaker: "Екатерина Ковтун",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "14:00-14:30",
          title:
            "Медицинская аргументация в маркетинговой стратегии (Фокус на таргетной терапии НЭО)",
          speaker: "Екатерина Ковтун",
        },
        {
          type: "lecture",
          time: "14:30-15:00",
          title: "Ключевые проекты 2023: Маркетинг и Медикал",
          speaker: "Екатрина Ковтун, Олег Ковальцов",
        },
        {
          type: "lecture",
          time: "15:00-15:15",
          title: "Лучшие практики КАМ: Наталья Быкова (Обучение в КНПЦ)",
        },
      ],
    },
    {
      streamName: "Неврология",
      zoomLink: "https://zoom.us/j/94294730546",
      items: [
        {
          type: "lecture",
          time: "09:00-09:50",
          title: "Цервикальная дистония. Marketing",
          speaker: "Тигран Торосян",
        },
        {
          type: "lecture",
          time: "09:50-10:40",
          title: "Цервикальная дистония. Medical team",
        },
        {
          type: "lecture",
          time: "10:40-11:00",
          title: "Цервикальная дистония. Market Access (Q&A)",
        },
        {
          type: "break",
          time: "11:00-11:15",
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "11:15-11:45",
          title: "Training plan 2023",
          speaker: "Лариса Островская",
        },
        {
          type: "lecture",
          time: "11:45-12:15",
          title: "BI & Account Planning Update",
          speaker: "Мария Красникова, Рудольф Данелян",
        },
        {
          type: "lecture",
          time: "12:15-13:15",
          rowSpan: 2,
          title:
            "Ролевые игры с детейлером «Цервикальная дистония» (4 rooms by regional teams) 4 calls by 15 min",
        },
        {
          type: "break",
          time: "13:15-14:00",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "14:00-15:00",
          title:
            "Ролевые игры с детейлером «Детская спастичность» (4 rooms by regional teams) 4 calls by 15 min",
        },
        {
          type: "lecture",
          time: "15:00-16:00",
          title:
            "Лучшие региональные практики. 1 проект от каждого региона. 15 минут на представление проекта",
          rowSpan: 2,
        },
      ],
    },
    {
      streamName: "Эстетика",
      zoomLink: "https://zoom.us/j/94226299527",
      items: [
        {
          type: "lecture",
          time: "09:00-10:40",
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          rowSpan: 3,
        },
        {
          type: "break",
          time: "10:40-11:00",
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "11:00-13:00",
          rowSpan: 4,
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "«Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          rowSpan: 3,
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Микс",
      zoomLink: "https://zoom.us/j/96146899963",
      items: [
        {
          type: "lecture",
          time: "07:00-09:00",
          title: "Диферелин маркетинг",
        },
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "Цервикальная дистония. Marketing",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/94294730546",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "10:00-10:30",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "10:30-14:00",
          title: "Диферелин регионы",
          rowSpan: 5,
        },
        {
          type: "gap",
          rowSpan: 3,
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Эстетика",
      zoomLink: "https://zoom.us/j/94226299527",
      items: [
        {
          type: "lecture",
          time: "09:00-10:40",
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/94226299527",
          rowSpan: 3,
        },
        {
          type: "break",
          time: "10:40-11:00",
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "11:00-13:00",
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/94226299527",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
          colSpan: 3,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "«Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/94226299527",
          rowSpan: 3,
        },
      ],
    },
    {
      streamName: "Регуляторный отдел",
      zoomLink: "https://us06web.zoom.us/j/88101397369",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "Передача дел PV мед.отделу",
          speaker: "PV отдел + Наталья Андреева, Евгения Бухарова",
          rowSpan: 3,
        },
        {
          type: "break",
          time: "10:00-10:20",
          title: "Кофе-пауза",
          colSpan: 2,
        },
        {
          type: "lecture",
          time: "10:20-13:00",
          title: "Передача дел PV мед.отделу",
          speaker: "PV отдел + Наталья Андреева, Евгения Бухарова",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "Регуляторный план 2023, обсуждение текущих и новых проектов",
          speaker: "RA team Russia and KZ",
          rowSpan: 3,
        },
      ],
    },
    {
      streamName: "Финансовый отдел",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title: "Офлайн Тренинг",
          rowSpan: 3,
        },
        {
          type: "break",
          time: "10:00-10:20",
          title: "Кофе-пауза",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "10:20-13:00",
          title: "Офлайн Тренинг",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-16:00",
          title: "Офлайн Тренинг",
          rowSpan: 3,
        },
      ],
    },
  ],
  "03.02": [
    {
      streamName: "Онкология",
      zoomLink: "https://zoom.us/j/98181064588",
      items: [
        {
          type: "lecture",
          time: "09:00-09:45",
          title: "Конкурентное окружение - место препаратов Ipsen Роза ветров!",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "09:45-10.30",
          title: "Кабометикс и Диферелин vs конкуренты. Преимущества и выгоды",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
          colSpan: 2,
        },
        {
          type: "lecture",
          time: "10:45-13:30",
          title: "Ассертивность - навыки лидера",
          speaker: "Ольга Вяткина",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:30-14:00",
          title: "Обед",
          colSpan: 8,
        },
        {
          type: "lecture",
          time: "14:00-14:15",
          title: "Закрытие",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "https://zoom.us/meeting/register/tJYocuGgrj0uE9YZEPIBrYDup4lXXBoOUDyz",
          colSpan: 8,
        },
        {
          type: "lecture",
          time: "14:15-16:00",
          title: "Онкологическая команда из 39 уникальных Я. Поговорим о себе.",
          rowSpan: 4,
        },
      ],
    },
    {
      streamName: "Редкие заболевания",
      zoomLink: "https://zoom.us/j/95554120602",
      items: [
        {
          type: "lecture",
          time: "09:00-10:00",
          title:
            "Мнение эксперта: Черебилло В.Ю. «Транссфеноидальная хирургия соматотропином»",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "10:00-10:30",
          title: "MA Стратегия Соматулин Акро",
          speaker: "Дмитрий Нечипоренко",
          rowSpan: 2,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "11:00-11:15",
          title:
            "Лучшие практики КАМ: Альмира Хужина (госпитализация Акро по КСГ)",
        },
        {
          type: "lecture",
          time: "11:15-11:45",
          title: "Стратегия продвижения: фокус Акро",
          speaker: "Олег Ковальцов",
        },
        {
          type: "lecture",
          time: "11:45-12:30",
          title:
            "Медицинская аргументация в маркетинговой стратегии (Фокус на комбинированной терапии Акро)",
          speaker: "Михаил Григорьев",
        },
        {
          type: "lecture",
          time: "12:30-13:00",
          title:
            "Медицинская аргументация в маркетинговой стратегии (Фокус мониторинг качества терапии Акро - САГИТ)",
          speaker: "Михаил Григорьев",
        },
        {
          type: "break",
          time: "13:00-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-14:15",
          title: "Закрытие",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "https://zoom.us/meeting/register/tJYocuGgrj0uE9YZEPIBrYDup4lXXBoOUDyz",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:15-15:00",
          title:
            "Медицинские и маркетинговые проекты Акро: ППП; Результаты АкроСкрин в МО; Обучение СЗ (ЭНЦ) 2023",
          speaker: "Михаил Григорьев, Олег Ковальцов",
          rowSpan: 2,
        },
        {
          type: "lecture",
          time: "15:00-15:15",
          title:
            "Лучшие практики КАМ: Светлана Королева, Юлия Родионова (Поиск пациентов и локальный АкроСкрин)",
          rowSpan: 2,
        },
      ],
    },
    {
      streamName: "Неврология",
      zoomLink: "https://zoom.us/j/91575496405",
      items: [
        {
          type: "lecture",
          time: "09:00-09:30",
          title:
            "Нейрогенная гиперактивность детрузора (NDO). Marketing strategy",
          speaker: "Тигран Торосян",
        },
        {
          type: "lecture",
          time: "09:30-09:50",
          title:
            "Нейрогенная гиперактивность детрузора (NDO). Market Access (Q&A)",
          speaker: "Дмитрий Нечипоренко",
        },
        {
          type: "lecture",
          time: "09:50-10:15",
          title: "Нейрогенная гиперактивность детрузора (NDO). Medical team",
        },
        {
          type: "lecture",
          time: "10:15-11:00",
          title: "Приглашеный TL (NDO)",
        },
        {
          type: "break",
          time: "11:00-11:15",
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "11:15-13:30",
          title:
            "Ролевые игры с детейлером «Взрослая спастичность» (4 rooms in ZOOM by regional teams) 5 calls by 15 min",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:30-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-14:15",
          title: "Закрытие",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "https://zoom.us/meeting/register/tJYocuGgrj0uE9YZEPIBrYDup4lXXBoOUDyz",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:15-15:40",
          title: "Regional crossfunctional meeting (FF+RMAMs+Msl)",
          rowSpan: 3,
        },
        {
          type: "lecture",
          time: "15:40-16:00",
          title: "Закрытие",
          speaker: "Виталий Терновых",
        },
      ],
    },
    {
      streamName: "Эстетика",
      zoomLink: "https://zoom.us/j/96574727037",
      items: [
        {
          type: "lecture",
          time: "09:00-10:30",
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "10:30-10:45",
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "10:45-13:30",
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:30-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-14:15",
          title: "Закрытие",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "https://zoom.us/meeting/register/tJYocuGgrj0uE9YZEPIBrYDup4lXXBoOUDyz",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:15-15:45",
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          rowSpan: 3,
        },
        {
          type: "lecture",
          time: "15:45-16:00",
          title: "Закрытие и награждение Эстетика",
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Микс",
      zoomLink: "https://zoom.us/j/99408668688",
      items: [
        {
          type: "lecture",
          time: "07:00-09:00",
          title: "Акромегалия маркетинг рынок, конкуренты",
        },
        {
          type: "lecture",
          time: "09:00-10:00",
          rowSpan: 3,
          title:
            "Мнение эксперта: Черебилло В.Ю. «Транссфеноидальная хирургия соматотропином»",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          zoomLink: "https://zoom.us/j/95554120602",
        },
        {
          type: "break",
          time: "10:00-10:30",
          title: "Обед",
        },
        {
          type: "lecture",
          time: "10:30-12:30",
          title: "Акромегалия регионы",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:30-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-14:15",
          title: "Закрытие",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "https://zoom.us/meeting/register/tJYocuGgrj0uE9YZEPIBrYDup4lXXBoOUDyz",
          desktopHidden: true,
        },
        {
          type: "gap",
          rowSpan: 4,
        },
      ],
    },
    {
      streamName: "Ipsen Казахстан Эстетика",
      zoomLink: "https://zoom.us/j/96574727037",
      items: [
        {
          type: "lecture",
          time: "09:00-10:30",
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "10:30-10:45",
          colSpan: 2,
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "10:45-13:30",
          title: "Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:30-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-14:15",
          title: "Закрытие",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "https://zoom.us/meeting/register/tJYocuGgrj0uE9YZEPIBrYDup4lXXBoOUDyz",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:15-16:00",
          title: "«Онлайн СОРЕВНОВАНИЕ КАМов «IPSEN»",
          speaker: "ОБЩАЯ СЕССИЯ С КЛАСТЕРОМ",
          rowSpan: 4,
        },
      ],
    },
    {
      streamName: "Финансовый отдел",
      items: [
        {
          type: "lecture",
          time: "09:00-10:30",
          title: "Офлайн Тренинг",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "10:30-10:45",
          desktopHidden: true,
          title: "Кофе-пауза",
        },
        {
          type: "lecture",
          time: "10:45-13:30",
          title: "Офлайн Тренинг",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:30-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-14:15",
          title: "Закрытие",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "https://zoom.us/meeting/register/tJYocuGgrj0uE9YZEPIBrYDup4lXXBoOUDyz",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:15-16:00",
          title: "Офлайн Тренинг",
          rowSpan: 4,
        },
      ],
    },
    {
      streamName: "Отдел по доступу препаратов на рынок",
      zoomLink: "https://us06web.zoom.us/j/83700091314",
      items: [
        {
          type: "gap",
          rowSpan: 5,
        },
        {
          type: "lecture",
          time: "10:30-12:00",
          title:
            "Интерпретация и применение на практике данных фармакоэкономических исследований препаратов Ipsen",
          rowSpan: 4,
        },
        {
          type: "break",
          time: "13:30-14:00",
          title: "Обед",
          desktopHidden: true,
        },
        {
          type: "lecture",
          time: "14:00-14:15",
          title: "Закрытие",
          speaker: "Ирина Зотова и Команда директоров Ipsen",
          zoomLink: "https://zoom.us/meeting/register/tJYocuGgrj0uE9YZEPIBrYDup4lXXBoOUDyz",
          desktopHidden: true,
        },
        {
          type: "gap",
          rowSpan: 4,
        },
      ],
    },
  ],
};
