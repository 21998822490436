import { memo, useCallback, useContext, useEffect, useState } from "react";
import {
  AlertCircle,
  ExternalLink,
  HelpCircle,
  MessageCircle,
  Smile,
} from "react-feather";
import { Room } from "src/screens/Live";
import { AppContext } from "src/shared/app-context";
import { ADMINS, CAN_SHOW_QUESTIONS } from "src/shared/config";
import { validateMessages } from "./lib/validator";
import ChatMessage from "./ui/ChatMessage";
import PollMessage from "./ui/PollMessage";
import Stickers from "./ui/Stickers";
// import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";
// import OpenedUser from "../OpenedUser/OpenedUser";
import { Link } from "react-router-dom";

import { getAnalytics, logEvent } from "firebase/analytics";
import Timer from "./ui/Timer";
import { Logger } from "src/shared/lib/logger";
import { Race } from "./ui/Race";
import MemoizedButton from "src/shared/components/Button";

const POINTS_FOR_QUESTION = 3;

export interface Message {
  id: number;
  created_at: Date;
  author_id: string;
  author_name: string;
  author_photo: string;
  author_type: string;
  message: string;
  hidden: boolean;
  is_question: boolean;
  points: number;
  tmp?: boolean;
}

function Chat({
  messages: roomMessages,
  poll,
  link,
  timer,
  zoomLink,
}: {
  messages: Message[];
  poll: Room["poll"];
  link?: {
    url: string;
    label: string;
  };
  timer?: number | null;
  zoomLink?: string;
}) {
  const analytics = getAnalytics();
  const { user, client, blacklist, setBlacklist } = useContext(AppContext);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>(roomMessages);
  const [tmpMessages, setTmpMessages] = useState<Message[]>([]);
  const [, setOpenedUser] = useState<string | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const [stickersShow, setStickersShow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        const playerWrap = document.getElementById("player_wrap")!;
        setMaxHeight(playerWrap.clientHeight);
      } else {
        setMaxHeight(0);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // TODO: remove only old or existed tmp messages
    setTmpMessages([]);
    setMessages(roomMessages);

    // setTimeout(() => {
    //   // if mobile
    //   if (window.innerWidth < 1024) {
    //     const element = document.getElementById("last-chat-element");
    //     scrollIntoViewIfNeeded(element!, {
    //       behavior: "smooth",
    //     });
    //   }
    // }, 50);
  }, [roomMessages]);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !!message.trim().length) {
      sendMessage(message);
    }
  };

  const sendMessage = useCallback(
    async (text: string) => {
      const message = text.trim();
      if (message.length === 0 || !client || !user) {
        return;
      }

      logEvent(analytics, "chat_message");
      setMessage("");

      const newMessage = {
        author_id: user.id,
        author_name: `${user!.main_info!.fname} ${user!.main_info!.lname}`,
        author_photo: user!.main_info!.photo,
        author_type: user!.type,
        message,
        hidden: false,
      };

      const allMessages = [...messages, ...tmpMessages].sort((a, b) =>
        a.id > b.id ? 1 : -1
      );
      const lastId = allMessages[allMessages.length - 1]?.id + 1 ?? 99999;

      setTmpMessages([
        ...tmpMessages,
        {
          ...newMessage,
          id: lastId,
          created_at: new Date(),
          tmp: true,
          hidden: false,
          is_question: false,
          points: 0,
        },
      ]);

      await client.from("chat").insert(newMessage);
      Logger.log(user.id, "chat", message, client);
    },
    [analytics, client, messages, tmpMessages, user]
  );

  const hideMessage = useCallback(
    async (messageId: number, hidden: boolean) => {
      await client!.from("chat").update({ hidden }).eq("id", messageId);
    },
    [client]
  );

  const onSetQuestion = useCallback(
    async (messageId: number, is_question: boolean) => {
      await client!
        .from("chat")
        .update({ is_question, points: POINTS_FOR_QUESTION })
        .eq("id", messageId);
    },
    [client]
  );

  const onSetPoints = useCallback(
    async (messageId: number, points: number) => {
      await client!.from("chat").update({ points }).eq("id", messageId);
    },
    [client]
  );

  const onBan = (userId: string) => {
    const newBlacklist = blacklist.find((id) => id === userId)
      ? [...blacklist.filter((id) => id !== userId)]
      : [...blacklist, userId];
    setBlacklist(newBlacklist);
  };

  const allMessages = validateMessages([...messages, ...tmpMessages]).sort(
    (a, b) => (a.id > b.id ? -1 : 1)
  );

  // let openedUserPreData;

  // if (openedUser) {
  //   const messageFromThisAuthor = allMessages.find(
  //     (m) => m.author_id === openedUser
  //   );
  //   if (messageFromThisAuthor) {
  //     // Should be
  //     openedUserPreData = {
  //       name: messageFromThisAuthor.author_name,
  //       photo: messageFromThisAuthor.author_photo,
  //       type: messageFromThisAuthor.author_type,
  //     };
  //   }
  // }

  const canShowQuestions = false && CAN_SHOW_QUESTIONS.includes(user!.id);

  // Race
  const showRace = poll && poll.type === "race";

  if (zoomLink) {
    return (
      <div
        style={{ maxHeight: maxHeight > 0 ? maxHeight : undefined }}
        className="relative flex flex-col bg-gray-200 w-full h-full aspect-square md:aspect-square overflow-hidden text-gray-900 p-12 items-center justify-center"
      >
        <MessageCircle size={44} />
        <h3 className="text-xl font-semibold text-center mt-6">
          Общение в Zoom
        </h3>
        <p className="text-base text-center mt-2 mb-4 max-w-md">
          Для общения в рамках этого потока используйте чат в Zoom. Подключиться
          вы можете по кнопке ниже.
        </p>
        <MemoizedButton
          title={"Подключиться"}
          onClick={() => window.open(zoomLink, "_blank")}
        />
      </div>
    );
  }

  return (
    // <>
    //   <OpenedUser
    //     userId={openedUser}
    //     onClose={() => setOpenedUser(null)}
    //     preData={openedUserPreData}
    //   />
    <div
      style={{ maxHeight: maxHeight > 0 ? maxHeight : undefined }}
      className="relative flex flex-col bg-gray-200 w-full h-full rounded-none aspect-square md:aspect-square overflow-hidden text-gray-900"
    >
      {!!timer && <Timer dateToTimer={timer} onFinish={() => {}} />}

      {link && (
        <div className="right-1 flex justify-center items-center absolute top-1 left-1 z-10 px-4 py-3 bg-bg animate-show-in-from-top shadow-lg">
          <a
            className="w-full text-center text-sm text-white"
            href={link.url}
            target="_blank"
            rel="noreferrer"
          >
            {link.label}
          </a>
          <ExternalLink className="w-5 h-5 text-white ml-2" />
        </div>
      )}

      {canShowQuestions && (
        <Link
          to="/questions"
          className="z-10 absolute flex items-center justify-center right-4 top-4 w-10 h-10 bg-primary shadow-xl rounded-full"
        >
          <HelpCircle className="text-white w-5" />
        </Link>
      )}

      {/* Messages */}
      {showRace ? (
        <Race data={poll} />
      ) : (
        <>
          <div className="flex flex-col-reverse h-full w-full overflow-auto pt-4 pb-14">
            <div
              id="last-chat-element"
              className="flex w-full bg-gray-200 h-px flex-shrink-0"
            ></div>
            <PollMessage data={poll} />
            {allMessages.map((m) => {
              if (
                ADMINS.includes(m.author_id) &&
                m.author_name === "::system_poll::"
              ) {
                return (
                  <PollMessage
                    disabled
                    key={m.id}
                    data={JSON.parse(m.message)}
                  />
                );
              }
              return (
                <ChatMessage
                  inBlacklist={blacklist.includes(m.author_id)}
                  key={`${m.id}_${m.tmp ? "tmp" : "base"}`}
                  message={m}
                  user={user!}
                  onHide={(hide) => hideMessage(m.id, hide)}
                  onSetQuestion={(is_question) =>
                    onSetQuestion(m.id, is_question)
                  }
                  onSetPoints={(points) => onSetPoints(m.id, points)}
                  onOpenUser={() => setOpenedUser(m.author_id)}
                  onBan={() => onBan(m.author_id)}
                  // allowLinks={true}
                />
              );
            })}
          </div>

          <div className="absolute bottom-0 flex border-t p-2 bg-white w-full">
            <Stickers
              show={stickersShow}
              onChoose={(stickerMessage) => {
                logEvent(analytics, "chat_sticker");
                sendMessage(stickerMessage);
                setStickersShow(false);
              }}
            />
            <div className="absolute w-full h-full bg-white left-0 top-0 rounded-b-none"></div>
            <button
              disabled
              onClick={() => {}}
              style={{ backgroundImage: `url(${user!.main_info!.photo})` }}
              className="relative flex-shrink-0 mr-2 w-10 h-10 rounded-full border bg-cover"
            />
            <input
              type="text"
              value={message}
              onKeyDown={handleKeyDown}
              onChange={(e) => setMessage(e.target.value)}
              className="relative text-sm px-2 border rounded-r-none border-r-0 bg-transparent w-full outline-none focus:border-primary pr-9"
            />
            <button
              onClick={() => setStickersShow(!stickersShow)}
              className="absolute w-10 h-10 right-12 text-primary flex-shrink-0 items-center justify-center flex"
            >
              <Smile className="w-5 h-5" />
            </button>
            <button
              onClick={() => sendMessage(message)}
              className="text-white bg-primary relative flex items-center justify-center w-10 h-10 flex-shrink-0"
            >
              <MessageCircle className="w-5 h-5" />
            </button>
          </div>
        </>
      )}
    </div>
    // </>
  );
}

const MemoizedChat = memo(Chat);
export default MemoizedChat;
