export const STICKERS = [
  "custom_1",
  "custom_2",
  "custom_3",
  "custom_4",
  "custom_5",
  "custom_6",
  "custom_7",
  "custom_8",
  // "custom_9",
  // "custom_10",
  // "custom_11",
  // "custom_12",
  "eyeslove",
  "fire",
  "glasses",
  "cry",
  "cute",
  "love",
  "laugh",
  "wow",
  "devil",
  "hmm",
  "meh",
  "power",
  "shit",
  "spiderman",
  "star",
  "blacklove",
];

export const DIRTY_WORDS = ["игил"];

export const DIRTY_WORDS1 = [
  "война",
  "войны",
  "войне",
  "войну",
  "войной",
  "войною",
  "войнах",
  "войнами",
  "насилие",
  "насилия",
  "насилию",
  "насилием",
  "насилиях",
  "убийство",
  "убийства",
  "убийству",
  "убийством",
  "убийствах",
  "смерть",
  "смерти",
  "смертям",
  "смертями",
  "смертях",
  "бой",
  "боя",
  "бою",
  "боем",
  "бое",
  "бои",
  "боев",
  "боям",
  "боями",
  "боях",
  "расчленение",
  "расчленения",
  "расчленению",
  "расчленением",
  "расчленениях",
  "мина",
  "мины",
  "мине",
  "мину",
  "миной",
  "минами",
  "минах",
  "пуля",
  "пули",
  "пуле",
  "пулю",
  "пулей",
  "пулями",
  "пулях",
  "пистолет",
  "пистолета",
  "пистолету",
  "пистолетом",
  "пистолетах",
  "нож",
  "ножа",
  "ножу",
  "ножом",
  "ножах",
  "реклама",
  "рекламы",
  "рекламе",
  "рекламу",
  "рекламой",
  "рекламою",
  "рекламах",
  "рекламами",
  "наркотик",
  "наркотика",
  "наркотику",
  "наркотиком",
  "наркотиках",
  "наркотиками",
  "наркомания",
  "наркомании",
  "наркоманией",
  "наркомании",
  "наркоманиям",
  "наркоманиями",
  "наркоманиях",
  "порно",
  "проституция",
  "проституции",
  "проституцией",
  "проституциям",
  "проституциями",
  "проституциях",
  "терроризм",
  "экстремизм",
  "нацизм",
  "расизм",
  "гомофобия",
  "путин",
  "путина",
  "путину",
  "путиным",
  "путине",
  "шойгу",
  "шойги",
  "шойгу",
  "шойго",
  "шойгом",
  "шойге",
  "медведев",
  "медведева",
  "медведеву",
  "медведевым",
  "медведеве",
  "зеленский",
  "зеленского",
  "зеленскому",
  "зеленским",
  "зеленском",
  "байден",
  "байдена",
  "байдену",
  "байденом",
  "байдене",
  "симонян",
  "симоняна",
  "симоняну",
  "симоняном",
  "симоняне",
  "сво ",
  "специальная военная операция",
  "специальной военной операции",
  "специальной военной операцией",
  "специальных военных операциях",
  "специальными военными операциями",
  "выбор",
  "выбора",
  "выбору",
  "выбором",
  "выборе",
  "выборы",
  "выборов",
  "выборам",
  "выборами",
  "выборах",
];
