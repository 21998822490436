import { memo, useContext, useEffect, useRef, useState } from "react";
import { Room } from "src/screens/Live";
import { STICKERS } from "../Chat/config";
import { validateMessages } from "../Chat/lib/validator";
import { Message } from "../Chat";
import { AppContext } from "src/shared/app-context";
import { isEqual } from "lodash";
import { ArrowRight, Check } from "react-feather";
import Cloud from "../Chat/ui/Cloud";
import { Racer } from "../Chat/ui/Race";

function Obs({ room }: { room: Room }) {
  const { client } = useContext(AppContext);
  const [questions, setQuestions] = useState<Message[]>([]);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const questionsRef = useRef<Message[]>([]);

  useEffect(() => {
    const syncer = async () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      const { data } = await client!
        .from("chat")
        .select()
        .eq("is_question", true)
        .order("created_at", { ascending: false });

      const newData = data ?? [];
      if (!isEqual(newData, questionsRef.current)) {
        questionsRef.current = newData;
        setQuestions(newData);
      }
      timeout.current = setTimeout(syncer, 1000);
    };

    syncer();
  }, [client]);

  const setAnswered = async (id: number) => {
    await client!
      .from("chat")
      .update({
        is_question: false,
      })
      .eq("id", id);
  };

  const setAsQuestion = async (id: number) => {
    await client!
      .from("chat")
      .update({
        is_question: true,
        points: 3,
      })
      .eq("id", id);
  };

  let rightTitle = "Вопросы спикерам";
  if (room.poll) {
    rightTitle = "Голосование";
    if (room.poll.type === "quiz") {
      rightTitle = "Викторина";
      if (room.poll.action === "winners") {
        rightTitle = "Победители";
      }
    }
    if (room.poll.type === "race") {
      rightTitle = "Гонки";
      if (room.poll.action === "winners") {
        rightTitle = "Победители";
      }
    }
  }

  const cloudWords = room.poll
    ? Object.keys(room.poll.results).map((word) => {
        return {
          text: word.toUpperCase(),
          value: room.poll!.results[word],
        };
      })
    : [];

  const total =
    room.poll?.type === "poll"
      ? Object.values(room.poll!.results).reduce((a, b) => a + b, 0)
      : 0;

  return (
    <>
      <div className="fixed w-full h-full left-0 top-0 bg-gray-900"></div>

      <div className="relative w-full mx-auto p-20 pb-0 text-white h-screen">
        <div className="w-full flex space-x-20 h-full">
          <div className="w-1/2 h-full flex flex-col">
            <h2 className="text-5xl text-yellow-500 font-semibold uppercase italic tracking-wider text-custom">
              Чат <span className="text-3xl">(новые сверху)</span>
            </h2>
            <hr className="mt-10 border-yellow-500" />

            <div className="pt-8 w-full h-full overflow-auto flex flex-col space-y-8 scrollbar-hide pb-8">
              {validateMessages(room.chat.items)
                .filter((m) => !m.hidden && m.author_name !== "::system_poll::")
                .map((m) => {
                  const isSticker = STICKERS.includes(
                    m.message.replace(/:/g, "")
                  );
                  const isCustomSticker = m.message.includes("custom_");

                  return (
                    <div
                      key={m.id}
                      className="flex space-x-8 text-white items-center"
                    >
                      <div
                        style={{ backgroundImage: `url(${m.author_photo})` }}
                        className="w-20 h-20 rounded-full flex-shrink-0 mb-auto bg-cover bg-center"
                      />

                      <div className="w-full flex flex-col">
                        <p className="text-yellow-600 font-semibold text-2xl">
                          {m.author_name}
                        </p>
                        {isSticker ? (
                          <img
                            src={`/images/stickers/${m.message.replace(
                              /:/g,
                              ""
                            )}.png`}
                            alt={m.message}
                            className={
                              isCustomSticker
                                ? "w-64 h-64 bg-white p-4 rounded-3xl mt-4"
                                : "w-40 h-40 mt-4"
                            }
                          />
                        ) : (
                          <p className="text-3xl">{m.message}</p>
                        )}
                      </div>
                      <button
                        onClick={() => setAsQuestion(m.id)}
                        className="mb-auto flex items-center justify-center w-16 h-16 border border-yellow-600/50 p-4 flex-shrink-0 rounded-2xl"
                      >
                        <ArrowRight className="text-yellow-600" />
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="w-1/2 h-full flex flex-col">
            <h2 className="text-5xl text-yellow-500 font-semibold uppercase italic tracking-wider text-custom">
              {rightTitle}
            </h2>
            <hr className="mt-10 border-yellow-500" />

            <div className="pt-8 w-full h-full overflow-auto flex flex-col space-y-8 scrollbar-hide">
              {room.poll === null &&
                questions.map((m) => {
                  const isSticker = STICKERS.includes(
                    m.message.replace(/:/g, "")
                  );
                  const isCustomSticker = m.message.includes("custom_");

                  return (
                    <div
                      key={m.id}
                      className="flex space-x-8 text-white items-center"
                    >
                      <img
                        src={m.author_photo}
                        className="w-20 h-20 rounded-full flex-shrink-0 mb-auto"
                        alt="user avatar"
                      />

                      <div className="w-full flex flex-col">
                        <p className="text-yellow-600 font-semibold text-2xl">
                          {m.author_name}
                        </p>
                        {isSticker ? (
                          <img
                            src={`/images/stickers/${m.message.replace(
                              /:/g,
                              ""
                            )}.png`}
                            alt={m.message}
                            className={
                              isCustomSticker
                                ? "w-64 h-64 bg-white p-4 rounded-3xl mt-4"
                                : "w-40 h-40 mt-4"
                            }
                          />
                        ) : (
                          <p className="text-3xl">{m.message}</p>
                        )}
                      </div>
                      <button
                        onClick={() => setAnswered(m.id)}
                        className="flex items-center justify-center w-16 h-16 border border-yellow-600/50 p-4 flex-shrink-0 rounded-2xl"
                      >
                        <Check className="text-yellow-600" />
                      </button>
                    </div>
                  );
                })}

              {room.poll !== null && (
                <div className="flex flex-col">
                  {room.poll.action !== "winners" &&
                    room.poll.type !== "race" && (
                      <h3 className="text-5xl text-white font-semibold mb-8">
                        {room.poll.name}
                      </h3>
                    )}
                  {room.poll.type === "race" &&
                    room.poll.action !== "winners" && (
                      <h3 className="text-5xl text-white font-semibold mb-8">
                        {room.poll.action === "prepare"
                          ? "Готовимся..."
                          : "Текущий заезд (это не порядок победителей!)"}
                      </h3>
                    )}
                  {room.poll.type === "words-cloud" && (
                    <div className="bg-white rounded-3xl w-full h-[700px] overflow-hidden p-4">
                      <Cloud
                        obs
                        id={room.poll.id}
                        data={
                          cloudWords.length > 50
                            ? cloudWords.slice(0, 100)
                            : cloudWords
                        }
                      />
                    </div>
                  )}
                  {room.poll.type === "poll" &&
                    room.poll.items.map((a) => {
                      const result = room.poll!.results[a.id] ?? 0;
                      const percent = Math.round((result / total) * 100) || 0;
                      return (
                        <div
                          key={a.id}
                          className={`mb-6 overflow-hidden relative flex w-full rounded-2xl items-center text-left text-4xl border border-yellow-600/50 p-8`}
                        >
                          <div
                            style={{ width: `${percent}%` }}
                            className="absolute h-full left-0 bg-yellow-600/30 transition-all duration-500"
                          ></div>
                          <span className="relative">{a.name}</span>
                          <span className="pl-2 flex items-center relative ml-auto text-3xl text-yellow-600">
                            {percent.toFixed(1)}%
                          </span>
                        </div>
                      );
                    })}

                  {room.poll.type === "quiz" &&
                    room.poll.action !== "winners" &&
                    room.poll.items.map((a) => {
                      return (
                        <div
                          key={a.id}
                          className={`mb-6 overflow-hidden relative flex w-full rounded-2xl items-center text-left text-4xl border border-yellow-600/50 p-8`}
                        >
                          <span className="relative">{a.name}</span>
                        </div>
                      );
                    })}

                  {(room.poll.type === "quiz" || room.poll.type === "race") &&
                    room.poll.action === "winners" &&
                    (room.poll.results as any)
                      .slice(0, room.poll.type === "quiz" ? 10 : 10)
                      .map((u: any, index: number) => {
                        return (
                          <div
                            key={u.id}
                            className="flex space-x-8 text-white items-center w-full mb-12"
                          >
                            <p className="text-custom italic text-4xl font-semibold text-yellow-500 w-10 text-center">
                              {index + 1}
                            </p>
                            <div
                              style={{
                                backgroundImage: `url(${u.main_info.photo})`,
                              }}
                              className={`bg-cover bg-center w-20 h-20 rounded-full flex-shrink-0 mb-auto ${
                                index === 1 ? "animation-delay-100" : ""
                              } ${index === 2 ? "animation-delay-200" : ""} ${
                                index < 3 ? "animate-bounce" : ""
                              }`}
                            />

                            <p className="ml-2 font-semibold w-full text-4xl">
                              {u.main_info.fname}
                            </p>
                            <p className="text-custom italic ml-auto text-2xl text-green-500 w-32 pr-1 text-right font-semibold">
                              {u.points}
                            </p>
                          </div>
                        );
                      })}

                  {room.poll.type === "race" &&
                    room.poll.action !== "winners" &&
                    (room.poll.results as unknown as Racer[])
                      .sort((a, b) => b.clicks - a.clicks)
                      .slice(0, 10)
                      .map((u: Racer, index: number) => {
                        return (
                          <div
                            key={u.author_id}
                            className="flex space-x-8 text-white items-center w-full mb-12"
                          >
                            <p className="text-custom italic text-4xl font-semibold text-yellow-500 w-10 text-center">
                              {index + 1}
                            </p>
                            <div
                              style={{
                                backgroundImage: `url(${u.author_photo})`,
                              }}
                              className={`bg-cover bg-center w-20 h-20 rounded-full flex-shrink-0 mb-auto`}
                            />

                            <p className="ml-2 font-semibold w-full text-4xl">
                              {u.author_name}
                            </p>
                            <p className="text-custom italic ml-auto text-2xl text-green-500 w-32 pr-1 text-right font-semibold">
                              {u.clicks}
                            </p>
                          </div>
                        );
                      })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedObs = memo(Obs);
export default MemoizedObs;
