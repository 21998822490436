import { sample } from "lodash";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { Room } from "src/screens/Live";
import { AppContext } from "src/shared/app-context";

import finish from "../assets/race/finish.png";
import head from "../assets/race/head.png";
import snowman from "../assets/race/snowman.png";

import you from "../assets/race/you.svg";
import me from "../assets/race/me.png";
import enemy from "../assets/race/enemy.png";

import correct from "../assets/race/tangerine.png";
import wrong1 from "../assets/race/socks.png";
import wrong2 from "../assets/race/tree.png";
import wrong3 from "../assets/race/gift.png";

const CLICK_COUNT = 50;
const MAX_TO_SHOW = 15;

export type Racer = {
  author_id: string;
  author_photo: string;
  author_name: string;
  clicks: number;
};

function Race({ data }: { data: Room["poll"] }) {
  const { user, client } = useContext(AppContext);
  const [clicks, setClicks] = useState(0);
  const [next, setNext] = useState(0);

  const racers: Racer[] = useMemo(() => {
    if (!data) {
      return [];
    }
    const results = data.results as unknown as Racer[];
    return results;
  }, [data]);

  const finishersCount = racers.filter((r) => r.clicks >= CLICK_COUNT).length;

  const filteredRacers = [
    ...racers.filter((r) => r.author_id !== user!.id).slice(0, MAX_TO_SHOW - 1),
    racers.find((r) => r.author_id === user!.id) ?? {
      author_id: user!.id,
      author_photo: user!.main_info!.photo,
      clicks: 0,
    },
  ]
    .map((r) => ({
      ...r,
      clicks: parseInt(r.clicks as unknown as string),
    }))
    .sort((a, b) => (b.author_id > a.author_id ? 1 : -1));

  useEffect(() => {
    const me = racers.find((r) => r.author_id === user!.id);
    if (me && me.clicks > clicks) {
      setClicks(me.clicks);
    }
  }, [clicks, racers, user]);

  const setAction = async () => {
    const numbers = [0, 1, 2, 3].filter((c) => c !== next);
    setNext(sample(numbers)!);

    if (data?.action === "prepare") {
      const { error } = await client!.from("poll").insert({
        author_id: user!.id,
        poll_id: data.id,
        answer: 0,
        unique_id: `${data.id}-${user!.id}`,
      });
      if (error) {
        await client!
          .from("poll")
          .update({ answer: 0 })
          .eq("unique_id", `${data!.id}-${user!.id}`);
      }
      return;
    }

    setClicks(Math.min(parseInt(clicks as unknown as string) + 1, CLICK_COUNT));

    if (clicks < CLICK_COUNT - 1) {
      // Update clicks
      if (clicks === 0) {
        const answerEntity = {
          author_id: user!.id,
          poll_id: data!.id,
          answer: clicks,
          unique_id: `${data!.id}-${user!.id}`,
        };
        await client!.from("poll").insert(answerEntity);
      } else {
        await client!
          .from("poll")
          .update({
            answer: clicks + 1,
          })
          .eq("unique_id", `${data!.id}-${user!.id}`);
      }
    } else {
      // Set winner
      await client!.from("race_winners").insert({
        race_id: data!.id,
        author_id: user!.id,
        unique_id: `${data!.id}-${user!.id}`,
      });

      // Update clicks
      await client!
        .from("poll")
        .update({
          answer: clicks + 1,
        })
        .eq("unique_id", `${data!.id}-${user!.id}`);
    }
  };

  if (!user || !data) {
    return null;
  }

  let wrongIcons = [wrong1, wrong1, wrong2, wrong3];
  if (next === 1) {
    wrongIcons = [wrong1, wrong2, wrong3, wrong2];
  }
  if (next === 2) {
    wrongIcons = [wrong3, wrong1, wrong3, wrong2];
  }
  if (next === 3) {
    wrongIcons = [wrong2, wrong1, wrong3, wrong3];
  }

  if (data.action === "winners") {
    const minePosition =
      (data.results as any).findIndex((u: any) => u.id === user!.id) + 1;
    const me = (data.results as any).find((u: any) => u.id === user!.id);

    return (
      <div className="flex flex-col w-full text-black bg-gray-200 overflow-auto pt-4">
        <div className="bg-white p-4 rounded-xl rounded-tl-none mx-4 mb-4">
          <p className="text-xs font-semibold text-secondary">
            Победители заезда
          </p>
          <div className="flex flex-col mt-3">
            {(data.results as any).slice(0, 10).map((u: any, index: number) => {
              return (
                <div
                  key={u.id}
                  className={`${
                    index === data.results.length - 1
                      ? ""
                      : "border-b border-white/20 pb-3 mb-3"
                  } flex w-full items-center`}
                >
                  <p className="text-sm font-semibold text-secondary w-6 text-center">
                    {index + 1}
                  </p>
                  <div
                    style={{
                      backgroundImage: `url(${u.main_info.photo})`,
                    }}
                    className={`flex-shrink-0 w-8 h-8 bg-cover bg-center bg-gray-100 rounded-full ${
                      index === 1 ? "animation-delay-100" : ""
                    } ${index === 2 ? "animation-delay-200" : ""} ${
                      index < 3 ? "animate-bounce" : ""
                    }`}
                  />
                  <p className="ml-2 text-sm font-semibold">
                    {u.main_info.fname} ({u.main_info.lname})
                  </p>
                  <p className="ml-auto text-xs text-green-500 w-10 pr-1 text-right font-semibold">
                    {u.points}
                  </p>
                </div>
              );
            })}
          </div>
          {me && minePosition && (
            <div className="pt-4 pb-2 flex text-primary items-center justify-center border-t border-primary/20">
              <p className="text-sm font-semibold">
                Вы на {minePosition} месте
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full bg-white flex flex-col items-center justify-start relative overflow-hidden rounded-xl">
      <div className="absolute px-3 py-2 bg-white rounded-full text-red-500 text-sm z-10 font-bold top-4">
        {clicks} / {CLICK_COUNT}
      </div>
      <div className="absolute px-3 py-2 flex items-center bg-white rounded-full top-4 right-4 text-sm text-center justify-center font-bold text-red-500 z-20">
        <img src={finish} alt="finish" className="mr-1 w-4" />
        {finishersCount}
      </div>

      <img src={head} className="relative z-0" alt="header" />

      <div
        style={{ minHeight: 200, maxHeight: 300, backgroundColor: "#FCEFE3" }}
        className="w-full h-full flex flex-col justify-around pb-0 relative z-10"
      >
        <div
          style={{ backgroundColor: "#DD432C" }}
          className="absolute w-2 h-full top-0 right-12 bg-red-200"
        >
          <img
            style={{ maxWidth: "none" }}
            src={snowman}
            alt="snowman"
            className="absolute bottom-full w-24 left-1/2 -ml-9 -mb-2"
          />
        </div>
        {filteredRacers.map((c, index) => {
          return (
            <div
              key={c.author_id}
              style={{
                width: `calc(100% - 6rem)`,
                transform: "translateX(3rem)",
              }}
              className="h-px relative"
            >
              {/* <div
                style={{
                  backgroundColor: "#CAE2F3",
                  width: `${(c.count / CLICK_COUNT) * 100}%`,
                  height: 2,
                }}
                className="left-0 absolute bottom-0 -mr-1"
              ></div> */}
              {c.author_id === user.id ? (
                <div
                  style={{
                    left: `${(clicks / CLICK_COUNT) * 100}%`,
                  }}
                  className="-mt-9 w-6 h-6 z-10 relative transition-all duration-200"
                >
                  <div
                    style={{
                      backgroundImage: `url(${c.author_photo})`,
                      animationDuration: "0.4s",
                    }}
                    className={`${
                      clicks >= CLICK_COUNT || data.action === "prepare"
                        ? ""
                        : "animate-bounce"
                    } w-8 h-8 absolute -left-10 -bottom-3 border-2 border-white bg-cover bg-center rounded-full`}
                  ></div>
                  <img
                    src={you}
                    alt="you"
                    style={{ maxWidth: "none" }}
                    className="absolute left-full top-2 ml-2 w-20"
                  />
                  <img
                    src={me}
                    alt="me"
                    style={{ maxWidth: "none" }}
                    className="absolute w-8 -ml-2 -bottom-3"
                  />
                </div>
              ) : (
                <div
                  style={{
                    animationDelay: `${index * 0.12}s`,
                    left: `${(c.clicks / CLICK_COUNT) * 100}%`,
                  }}
                  className="-mt-9 w-6 h-6 relative z-1 transition-all duration-200"
                >
                  <div
                    style={{
                      backgroundImage: `url(${c.author_photo})`,
                      animationDuration: "0.4s",
                    }}
                    className={`${
                      c.clicks >= CLICK_COUNT || data.action === "prepare"
                        ? ""
                        : "animate-bounce"
                    } w-8 h-8 absolute -left-10 -bottom-3 border-2 border-white bg-cover bg-center rounded-full`}
                  ></div>
                  <img
                    src={enemy}
                    alt="me"
                    style={{ maxWidth: "none" }}
                    className="absolute -bottom-3 w-8 -ml-2"
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div
        style={{ backgroundColor: "rgb(0 146 200)" }}
        className="flex w-full bg-black py-2 justify-around px-4 relative"
      >
        {racers.find((c) => c.author_id === user.id) &&
        clicks >= CLICK_COUNT ? (
          <h2 className="text-xl font-semibold w-full text-center text-white py-3">
            Вы финишировали!
          </h2>
        ) : (
          <>
            {[0, 1, 2, 3].map((b) => {
              return (
                <button
                  key={b}
                  onClick={() => {
                    if (next === b) {
                      setAction();
                    }
                  }}
                  className={`${
                    next === b ? "" : "transform scale-75 opacity-60"
                  } transition duration-300 ease-in-out w-16 h-16 p-2 rounded-xl bg-white flex items-center justify-center text-indigo-600`}
                >
                  {next === b ? (
                    <img src={correct} alt="tangerine" />
                  ) : (
                    <img src={wrongIcons[b]} alt="tangerine" />
                  )}
                </button>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

const MemoizedRace = memo(Race);
export { MemoizedRace as Race };
