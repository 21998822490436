import { isEqual } from "lodash";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { User } from "src/App";
import AdminPanel from "src/features/AdminPanel";
import { PlanItem } from "src/features/AdminPanel/config";
import Chat, { Message } from "src/features/Chat";
import Obs from "src/features/Obs/Obs";
import { Program } from "src/features/Program";
import VideoPlayer from "src/features/VideoPlayer";
import { AppContext } from "src/shared/app-context";
import Header from "src/shared/components/Header";
import { ADMINS, CAN_SHOW_QUESTIONS, ROOM_ID } from "src/shared/config";
import { schedule as programConfig } from "src/features/Program/config";
import ScheduleScreen from "./Schedule";
import ShopScreen from "./Shop";

const DEFAULT_UPDATE_INTERVAL = 3000;

export interface Room {
  active: true;
  chat: {
    items: Message[];
  };
  created_at: Date;
  id: number;
  name: string;
  poll:
    | (PlanItem & {
        results: Record<number | string, number>;
      })
    | null;
  questions: null;
  state: {
    stream_url: string | null;
    timer?: number;
    schedule?: {
      item: number;
      speaker: string | null;
    };
    shop_blacklist?: User[];
    shop?: boolean;
    chat_link?: {
      url: string;
      label: string;
    };
  } | null;
  update_interval: number;
}

function LiveScreen() {
  const { user, client } = useContext(AppContext);
  const [room, setRoom] = useState<Room | null>(null);
  const lastRoomState = useRef<Room | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const deviceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [stream, setStream] = useState<string>(
    programConfig["30.01"][0].streamName
  );
  const streams = programConfig["30.01"].map((item) => item.streamName);

  useEffect(() => {
    const syncer = async () => {
      // console.log("[ LiveScreen ] Syncing");
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      const { data, error } = await client!
        .from("rooms")
        .select()
        .eq("id", ROOM_ID)
        .single();

      if (!data || error) {
        throw new Error("Room not found");
      }
      if (!isEqual(data, lastRoomState.current)) {
        setRoom(data);
        lastRoomState.current = data;
      }

      const updateInterval = data.update_interval ?? DEFAULT_UPDATE_INTERVAL;
      timeout.current = setTimeout(() => syncer(), updateInterval);
    };

    syncer();

    // const roomListener = client!
    //   .channel("room")
    //   .on(
    //     "postgres_changes",
    //     {
    //       event: "UPDATE",
    //       schema: "public",
    //       table: "rooms",
    //       filter: `id=eq.${ROOM_ID}`,
    //     },
    //     (payload: any) => {
    //       // Next need because payload new missed some fields sometimes
    //       const newRoom = {
    //         ...lastRoomState.current,
    //         ...payload.new,
    //       };
    //       setRoom(newRoom);
    //       lastRoomState.current = newRoom;
    //     }
    //   )
    //   .subscribe();

    // return () => {
    //   client!.removeChannel(roomListener);
    // };
  }, [client]);

  useEffect(() => {
    // const uniqueDeviceId = localStorage.getItem("uniqueDeviceId");
    // if (!user || !uniqueDeviceId || !client) {
    //   return;
    // }
    // const syncer = async () => {
    //   if (deviceTimeout.current) {
    //     clearTimeout(deviceTimeout.current);
    //   }
    //   const { data, error } = await client!
    //     .from("tickets")
    //     .select("devices")
    //     .eq("ticket", user.main_info?.ticket ?? "unknown");
    //   if (!data || error || data.length === 0) {
    //     await client?.auth.signOut();
    //     return;
    //   }
    //   const ticket = data![0];
    //   if (!ticket.devices.includes(uniqueDeviceId)) {
    //     await client?.auth.signOut();
    //     return;
    //   }
    //   deviceTimeout.current = setTimeout(() => syncer(), 10000);
    // };
    // deviceTimeout.current = setTimeout(() => syncer(), 10000);
  }, [client, user]);

  if (!room) {
    return (
      <div className="container max-w-md mx-auto px-6 text-white mt-8">
        <Header />
        <div className="mt-24 mx-auto w-10 h-10 border-2 rounded-full border-primary border-t-transparent animate-spin"></div>
      </div>
    );
  }

  const isAdmin = user && CAN_SHOW_QUESTIONS.includes(user.id);
  const showObs = window.location.hash === "#obs" && isAdmin;

  if (showObs) {
    return <Obs room={room} />;
  }

  const zoomLink = programConfig["30.01"].find(
    (streamItem) => streamItem.streamName === stream
  )?.zoomLink;

  return (
    <>
      <div className="container mx-auto px-6 text-white pt-4 pb-24">
        <div className="flex flex-col w-full full-container-h">
          <Header />

          <div className="relative hidden">
            <div className="flex overflow-auto">
              {streams.map((item) => (
                <button
                  key={item}
                  onClick={() => setStream(item)}
                  className={`${
                    stream === item
                      ? `border-primary text-primary border-b-gray-100`
                      : "text-bg/50 border-b-transparent"
                  } text-sm font-semibold border px-4 py-3 mr-2 whitespace-nowrap`}
                >
                  {item}
                </button>
              ))}
            </div>
            <hr className="mb-4 -mt-px" />
          </div>

          <div className="relative gap-4 w-full flex flex-col lg:grid lg:grid-cols-12 my-auto mt-8 lg:mt-12">
            <VideoPlayer state={room.state} zoomLink={undefined} />

            <Routes>
              <Route
                path="/"
                element={
                  <div className="lg:col-span-4 relative mt-4 lg:mt-0">
                    <div className="absolute h-8 -top-8 left-0 right-0 bg-white flex items-center justify-center text-primary text-xs italic font-semibold tracking-wide border-b">
                      ЧАТ КОЛЛЕГ
                    </div>
                    <Chat
                      timer={room.state?.timer}
                      link={room.state?.chat_link}
                      poll={room.poll}
                      messages={room.chat?.items ?? []}
                      zoomLink={undefined}
                    />
                  </div>
                }
              />
              <Route
                path="schedule"
                element={
                  <div className="lg:col-span-4 relative mt-4 lg:mt-0">
                    <div className="absolute h-8 -top-8 left-0 right-0 bg-white flex items-center justify-center text-primary text-xs italic font-semibold tracking-wide border-b">
                      ПРОГРАММА ТРАНСЛЯЦИИ
                    </div>
                    <ScheduleScreen state={room.state?.schedule} />
                  </div>
                }
              />
              {/* <Route
                path="shop"
                element={
                  <ShopScreen
                    status={!!room.state?.shop}
                    blacklist={room.state?.shop_blacklist ?? []}
                  />
                }
              /> */}
            </Routes>
            {isAdmin && ADMINS.includes(user.id) && <AdminPanel />}
          </div>
          <div className="relative text-black">
            <Program live={true} />
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedLiveScreen = memo(LiveScreen);
export default MemoizedLiveScreen;
