import { useState, useEffect, memo, useCallback } from "react";
import { createClient } from "@supabase/supabase-js";
import { Routes, Route } from "react-router-dom";

import { AppContext } from "./shared/app-context";
import AuthScreen from "./screens/Auth";
import ProfileScreen from "./screens/Profile";
import LiveScreen from "./screens/Live";
import QuestionsScreen from "./screens/Questions";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Logger } from "./shared/lib/logger";
// import Snowfall from "react-snowfall";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { nanoid } from "nanoid";

const supabase = createClient(
  "https://anjosocqbazmeihiepkn.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFuam9zb2NxYmF6bWVpaGllcGtuIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjkwNTM1NjAsImV4cCI6MTk4NDYyOTU2MH0.UyInWMWYfSriofXdKTko9JB03YfFGTGXasl6_ODDtuw"
);

const firebaseConfig = {
  apiKey: "AIzaSyCBdMzYy2vkqCv983UMH84cuoHOCRX0_No",
  authDomain: "pitcha5.firebaseapp.com",
  projectId: "pitcha5",
  storageBucket: "pitcha5.appspot.com",
  messagingSenderId: "524793909890",
  appId: "1:524793909890:web:bbe7cf20e708d1a11a9a9b",
  measurementId: "G-CWWKBVRJHR",
};

type Roles = "basic" | "vip" | "tester";

export interface User {
  id: string;
  type: Roles;
  main_info?: {
    fname: string;
    phone: string;
    lname: string;
    division: string;
    work_id: string;
    photo: string;
    comment: string;
    ticket: string;
  };
}

function BgParticles() {
  const particlesInit = useCallback(async (engine: any) => {
    await loadFull(engine);
  }, []);

  return null;

  return (
    <Particles
      id="particles"
      init={particlesInit}
      options={{
        background: {
          color: {
            value: "#F0F1F8",
          },
        },
        fpsLimit: 120,
        particles: {
          color: {
            value: ["#EFE5E5", "#E0E2F2"],
          },
          links: {
            color: "#E0E2F2",
            distance: 150,
            enable: true,
            opacity: 1,
            width: 2,
          },
          move: {
            enable: true,
            outModes: {
              default: "out",
            },
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 30,
          },
          opacity: {
            value: 1,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 5, max: 10 },
          },
        },
        detectRetina: true,
      }}
    />
  );
}

function BG() {
  return (
    <div className="fixed left-0 top-0 right-0 bottom-0 -z-50 pointer-events-none">
      <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient"></div>
      {/* <Snowfall /> */}
      {/* <div className="trees"></div> */}
    </div>
  );
}

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthed, setAuth] = useState<boolean | null>(null);
  const [blacklist, setBlacklist] = useState<string[]>([]);

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);

  useEffect(() => {
    const uniqueDeviceId = localStorage.getItem("uniqueDeviceId") ?? nanoid();
    localStorage.setItem("uniqueDeviceId", uniqueDeviceId);
  }, []);

  useEffect(() => {
    const setDeviceIntoTicket = async (ticket: string) => {
      const uniqueDeviceId = localStorage.getItem("uniqueDeviceId");

      const { data, error } = await supabase
        .from("tickets")
        .select("devices")
        .eq("ticket", ticket);

      if (!data || error || data.length === 0) {
        return;
      }

      const ticketData = data[0];
      const devices = ticketData.devices ?? [];
      if (devices.includes(uniqueDeviceId)) {
        return;
      }

      const newDevices = [...devices, uniqueDeviceId].slice(-2);
      await supabase
        .from("tickets")
        .update({ devices: newDevices })
        .eq("ticket", ticket);
    };

    const getUser = async (id: string) => {
      Logger.log(id, "logged", null, supabase);
      const userData = await supabase
        .from("users")
        .select()
        .eq("id", id)
        .limit(1);

      setAuth(true);

      const newUserData =
        userData.data && userData.data.length > 0
          ? userData.data[0]
          : {
              id,
            };

      setUser(newUserData);

      if (newUserData.main_info) {
        setDeviceIntoTicket(newUserData.main_info.ticket);
      }
    };

    const onChange = async () => {
      const currentUser = await supabase.auth.getUser();
      if (!currentUser || !currentUser.data || !currentUser.data.user) {
        setUser(null);
        setAuth(false);
        return;
      }

      getUser(currentUser.data.user.id);
    };

    onChange();

    supabase.auth.onAuthStateChange((event, session) => {
      onChange();
    });
  }, []);

  if (isAuthed === null) {
    return (
      <div className="fixed left-1/2 top-1/2 w-6 h-6 -m-3 rounded-full border-t-transparent border-2 border-primary animate-spin" />
    );
  }

  if (!isAuthed) {
    return (
      <AppContext.Provider
        value={{ client: supabase, user, setUser, blacklist, setBlacklist }}
      >
        <BgParticles />
        {/* <BG /> */}
        <AuthScreen />
      </AppContext.Provider>
    );
  }

  if (!user?.main_info) {
    return (
      <AppContext.Provider
        value={{ client: supabase, user, setUser, blacklist, setBlacklist }}
      >
        <BgParticles />
        <BG />
        <ProfileScreen />
      </AppContext.Provider>
    );
  }

  return (
    <AppContext.Provider
      value={{ client: supabase, user, setUser, blacklist, setBlacklist }}
    >
      <BgParticles />
      {/* <BG /> */}
      <div className="hidden bg-gray-900 scroll-smooth"></div>
      <Routes>
        <Route path="/*" element={<LiveScreen />} />
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/questions" element={<QuestionsScreen />} />
      </Routes>
    </AppContext.Provider>
  );
}

const MemoizedApp = memo(App);
export default MemoizedApp;
