const ARTYOM = "0dc3d6b2-da9b-4641-ba93-62c91854359c";
const SASHA = "5d8b1854-051a-4a2d-865e-2ae5d2ad2824";
// const MISSQA = "d577371e-1070-4f4f-b7bd-42f4b184be0d";
// const OPERATOR = "5d344d3d-7dfb-42e4-b66c-f260bc25f8c9";
// const SOMEUSER = "053cfa9d-5baa-4eef-9aad-932640803e64";
const OBSUSER = "b1d89dcb-2508-41cd-a28a-5b6b1f1ed862";

const MODERATOR1 = "df2eac3b-9185-4eca-80fb-274ffc6888d2";
const MODERATOR2 = "8bd37f43-db68-4b6f-bf75-facf209638cd";
const MODERATOR3 = "e8e15fd9-b83e-4489-b9f3-a24cb5d21c37";

const ADMINS = [ARTYOM, SASHA];
const MODERATORS = [ARTYOM, SASHA, MODERATOR1, MODERATOR2, MODERATOR3];

const CAN_SHOW_QUESTIONS = [
  ARTYOM,
  SASHA,
  OBSUSER,
  MODERATOR1,
  MODERATOR2,
  MODERATOR3,
];

const ROOM_ID = 1;
const MAIN_DOMAIN = "dpr-online.ru";

export { ADMINS, MODERATORS, CAN_SHOW_QUESTIONS, ROOM_ID, MAIN_DOMAIN };
