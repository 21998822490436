export type PlanItem = {
  id: number;
  type: "poll" | "words-cloud" | "rate" | "quiz" | "race";
  name: string;
  correct?: number;
  items: {
    id: number;
    name: string;
  }[];
  // For backend
  action?: string;
};

const PLAN: PlanItem[] = [
  {
    id: 101,
    type: "poll",
    name: "Готовы к конфе?",
    items: [
      {
        id: 1,
        name: "Естественно, коллега",
      },
      {
        id: 2,
        name: "Сначала кулер и кофе",
      },
    ],
  },
  {
    id: 301,
    type: "words-cloud",
    name: "Откуда вы нас смотрите, коллеги?",
    items: [],
  },
  {
    id: 302,
    type: "words-cloud",
    name: "Чего вы ждете от конференции, коллеги?",
    items: [],
  },
  {
    id: 303,
    type: "words-cloud",
    name: "Чье выступление вы хотите услышать больше всего?",
    items: [],
  },
  {
    id: 304,
    type: "words-cloud",
    name: "Как вам обстановочка на конфереции?",
    items: [],
  },
  {
    id: 305,
    type: "words-cloud",
    name: "Что изменится у вас в жизни после конференции КОЛЛЕГИ?",
    items: [],
  },
  {
    id: 102,
    type: "poll",
    name: "Ну что, коллеги, вы готовы",
    items: [
      {
        id: 1,
        name: "О да!",
      },
      {
        id: 2,
        name: "А что, уже пора?",
      },
    ],
  },
  {
    id: 103,
    type: "poll",
    name: "Как вы относитесь к трендам?",
    items: [
      {
        id: 1,
        name: "Я в реках, коллеги",
      },
      {
        id: 2,
        name: "Уважаю, этот комментарий за меня пишет ChatGPT",
      },
      {
        id: 3,
        name: "Тренды - мимолетное",
      },
    ],
  },
  {
    name: "Как вам лекция Евгения Давыдова, коллега?",
    items: [],
    id: 104,
    type: "rate",
  },
  {
    id: 105,
    type: "poll",
    name: "У вашего бизнеса есть узнаваемый TOV?",
    items: [
      {
        id: 1,
        name: "Да, мы не промах",
      },
      {
        id: 2,
        name: "Хочется его улучшить",
      },
      {
        id: 3,
        name: "Кто это?",
      },
    ],
  },
  {
    name: "Коллеги, как вам выступление Натальи Брянцевой?",
    items: [],
    id: 106,
    type: "rate",
  },
  {
    id: 107,
    type: "poll",
    name: "А вы давно встряхивали свой бизнес?",
    items: [
      {
        id: 1,
        name: "Регулярно!",
      },
      {
        id: 2,
        name: "У нас все стабильно, коллеги",
      },
      {
        id: 3,
        name: "Нам и так потрясений достаточно",
      },
    ],
  },
  {
    name: "Коллега, как вы оцените лекцию Даниила Шулейко?",
    items: [],
    id: 108,
    type: "rate",
  },
  {
    name: "Коллега, как дискуссия?",
    items: [],
    id: 109,
    type: "rate",
  },
  {
    id: 306,
    type: "words-cloud",
    name: "А что драйвит лично вас, коллеги?",
    items: [],
  },
  {
    id: 110,
    type: "poll",
    name: "Когда все идет не по плану, я:",
    items: [
      {
        id: 1,
        name: "Сажусь за новый план!",
      },
      {
        id: 2,
        name: "Плачу, обняв коленки",
      },
      {
        id: 3,
        name: "У меня все по плану",
      },
    ],
  },
  {
    name: "Что думаете насчет выступления Алины Чичиной, коллега?",
    items: [],
    id: 111,
    type: "rate",
  },
  {
    id: 307,
    type: "words-cloud",
    name: "Как вам интервью с Антоном?",
    items: [],
  },
  {
    name: "Как вам дискуссия, коллега?",
    items: [],
    id: 112,
    type: "rate",
  },
  {
    name: "А сколько звездочек вы поставите выступлению Тимуара Залкарнаева?",
    items: [],
    id: 113,
    type: "rate",
  },
  {
    id: 114,
    type: "poll",
    name: "Как поживает ваш личный бренд, коллеги?",
    items: [
      {
        id: 1,
        name: "Все сложно",
      },
      {
        id: 2,
        name: "Я наноинфлюенсер с большим потенциалом",
      },
      {
        id: 3,
        name: "Коллеги, готов сотрудничать",
      },
    ],
  },
  {
    name: "Как вам выступление Саши Жарковой, коллега?",
    items: [],
    id: 115,
    type: "rate",
  },
  {
    name: "И главный вопрос. Как вам конфа КОЛЛЕГИ?",
    items: [],
    id: 116,
    type: "rate",
  },
];

export { PLAN };
