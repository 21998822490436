import { memo, useCallback, useContext, useState } from "react";
import { Edit2, Trash, Video, Youtube } from "react-feather";
import { Room } from "src/screens/Live";
import { AppContext } from "src/shared/app-context";
import { ADMINS, ROOM_ID } from "src/shared/config";

import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import MemoizedButton from "src/shared/components/Button";
import { SPEAKERS } from "../Schedule/config";

function VideoPlayer({
  state,
  zoomLink,
}: {
  state: Room["state"];
  zoomLink?: string;
}) {
  const analytics = getAnalytics();

  const { user, client } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(
    parseInt(localStorage.getItem("videoTab") ?? "0")
  );
  const isAdmin = ADMINS.includes(user!.id);
  const noVideo = state === null || state.stream_url === null;

  const onUpdateStream = useCallback(async () => {
    const newStreamUrl = prompt(
      "Вставьте путь для встройки видео",
      state?.stream_url ?? undefined
    );
    if (newStreamUrl !== null) {
      await client!
        .from("rooms")
        .update({ state: { ...state, stream_url: newStreamUrl } })
        .eq("id", ROOM_ID);
    }
  }, [client, state]);

  const onClearStream = useCallback(async () => {
    const confirmed = window.confirm("Убрать видео у всех пользователей?");
    if (confirmed) {
      await client!
        .from("rooms")
        .update({ state: { ...state, stream_url: null } })
        .eq("id", ROOM_ID);
    }
  }, [client, state]);

  const onChangeTab = useCallback(
    (tab: number) => {
      setSelectedTab(tab);
      logEvent(analytics, "change_tab", { tab: tab + 1 });
      setUserProperties(analytics, { video_tab: tab + 1 });
      localStorage.setItem("videoTab", tab.toString());
    },
    [analytics]
  );

  if (noVideo && !isAdmin) {
    return (
      <div
        id="player_wrap"
        className="lg:col-span-8 overflow-hidden w-full aspect-video relative flex-shrink-0 bg-secondary"
      ></div>
    );
  }

  if (noVideo && isAdmin) {
    return (
      <div
        id="player_wrap"
        className="lg:col-span-8 overflow-hidden w-full aspect-video relative flex-shrink-0"
      >
        <button
          onClick={onUpdateStream}
          className="mb-6 overflow-hidden w-full aspect-video border border-gray-700"
        >
          <Video className="mx-auto text-yellow-500" />
        </button>
      </div>
    );
  }

  const videoUrls = state!.stream_url!.split(":::");

  if (videoUrls.length === 1) {
    return (
      <div
        id="player_wrap"
        className="lg:col-span-8 overflow-1hidden w-full aspect-video relative flex-shrink-0"
      >
        <RunningLine selectedScheduleId={state?.schedule?.item} />
        {zoomLink ? (
          <div className="w-full h-full bg-bg flex flex-col items-center justify-center p-4">
            <Youtube size={44} />
            <h3 className="mt-4 text-xl md:text-2xl font-semibold text-white text-center mb-2">
              Этот поток проходит в Zoom
            </h3>
            <p className="text-base hidden md:block text-white mb-4 max-w-md text-center">
              Этот поток проходит в Zoom, поэтому мы не можем показать его
              здесь. Для подключения нажмите на кнопку ниже.
            </p>
            <MemoizedButton
              title={"Подключиться"}
              onClick={() => window.open(zoomLink, "_blank")}
            />
          </div>
        ) : (
          <>
            <iframe
              className="w-full h-full"
              title="video"
              src={state!.stream_url!}
            ></iframe>
            {isAdmin && (
              <>
                <button
                  onClick={onUpdateStream}
                  className="flex items-center justify-center w-8 h-8 rounded-full absolute right-4 top-4 shadow-xl bg-primary"
                >
                  <Edit2 className="w-4 text-white" />
                </button>
                <button
                  onClick={onClearStream}
                  className="flex items-center justify-center w-8 h-8 rounded-full absolute right-4 bottom-4 shadow-xl bg-primary"
                >
                  <Trash className="w-4 text-white" />
                </button>
              </>
            )}
          </>
        )}
      </div>
    );
  } else {
    const selectedVideo =
      videoUrls[selectedTab > videoUrls.length - 1 ? 0 : selectedTab];
    return (
      <>
        <div className="col-span-4 lg:col-span-8 w-full flex space-x-2">
          {videoUrls.map((url, index) => (
            <button
              key={url}
              onClick={() => onChangeTab(index)}
              className={`${
                index === selectedTab
                  ? "bg-primary text-white border-primary"
                  : "border-primary text-primary hover:text-white hover:bg-primary"
              } rounded-full border px-2.5 py-1 text-xs `}
            >
              {index === 0 ? "Трансляция" : "Трансляция с сурдопереводом"}
            </button>
          ))}
        </div>
        <div
          id="player_wrap"
          className="col-span-8 lg:col-span-8 rounded-xl overflow-hidden w-full aspect-video relative"
        >
          <RunningLine selectedScheduleId={state?.schedule?.item} />
          <iframe
            className="w-full h-full"
            title="video"
            src={selectedVideo}
            frameBorder="0"
          ></iframe>
          {isAdmin && (
            <>
              <button
                onClick={onUpdateStream}
                className="flex items-center justify-center w-8 h-8 rounded-full absolute right-4 top-4 shadow-xl bg-primary"
              >
                <Edit2 className="w-4 text-white" />
              </button>
              <button
                onClick={onClearStream}
                className="flex items-center justify-center w-8 h-8 rounded-full absolute right-4 bottom-4 shadow-xl bg-primary"
              >
                <Trash className="w-4 text-white" />
              </button>
            </>
          )}
        </div>
      </>
    );
  }
}

function RunningLine({ selectedScheduleId }: { selectedScheduleId?: number }) {
  const currentSchedule = SPEAKERS.find(
    (speaker) => speaker.id === selectedScheduleId
  );

  const textLine = currentSchedule?.title ?? "КОЛЛЕГИ: МЕНЯЕМ БИЗНЕС";
  const speaker =
    currentSchedule?.speakers.length === 1
      ? currentSchedule?.speakers[0].speaker_name
      : undefined;

  return (
    <div className="absolute -top-8 w-full left-0 right-0 bg-white h-8 overflow-hidden">
      <div className="flex">
        {[0, 1, 2, 3, 4, 5].map((a) => (
          <p
            key={a}
            className="text-primary uppercase text-xs italic font-semibold tracking-wide pr-1 whitespace-nowrap mt-2 animate-text-line"
          >
            {textLine}
            {" • "}
            {speaker && (
              <span className="text-black">
                {speaker}
                {" • "}
              </span>
            )}
          </p>
        ))}
      </div>
    </div>
  );
}

const MemoizedVideoPlayer = memo(VideoPlayer);
export default MemoizedVideoPlayer;
