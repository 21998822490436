import { memo, useCallback, useContext, useState } from "react";
import {
  BarChart2,
  DollarSign,
  Eye,
  EyeOff,
  Loader,
  MessageCircle,
  Play,
  RefreshCcw,
  Square,
} from "react-feather";
import { AppContext } from "src/shared/app-context";
import Button from "src/shared/components/Button";
import Input from "src/shared/components/Input";
import { ROOM_ID } from "src/shared/config";
import { Racer } from "../Chat/ui/Race";
import { PLAN, PlanItem } from "./config";

function AdminPanel() {
  const { client, user } = useContext(AppContext);
  const [url, setUrl] = useState("");
  const [urlLabel, setUrlLabel] = useState("");
  const [currentItem, setCurrentItem] = useState<PlanItem | null>(null);
  const [winnersExport, setWinnersExport] = useState("");

  // useEffect(() => {
  //   const syncer = async () => {
  //     let testerEmails = TESTER_EMAILS.map((email) =>
  //       email.trim().toLowerCase()
  //     );
  //     // @ts-ignore
  //     testerEmails = [...new Set(testerEmails)];
  //     console.log(testerEmails.join(":::"));

  //     // testerEmails.forEach((email, index) => {
  //     //   console.log(testerEmails.length);
  //     //   client!.from("testers").insert({
  //     //     email,
  //     //     room_id: ROOM_ID,
  //     //   });
  //     // })
  //     // const { data, error } = await client!
  //     //   .from("tickets")
  //     //   .select("id, email")
  //     //   .gt("id", moreThan)
  //     //   .csv();
  //     // console.log(data?.length, data, error);
  //   };
  //   syncer();
  // }, [client]);

  const clearPoll = useCallback(async () => {
    if (!client) {
      return;
    }
    setCurrentItem(null);
    await client.from("rooms").update({ poll: null }).eq("id", ROOM_ID);
  }, [client]);

  const getWinners = useCallback(
    async (planItem: PlanItem) => {
      if (!client) {
        return;
      }

      // Quiz results

      // const { data } = await client
      //   .from("users")
      //   .select("id, points, speed_points, main_info")
      //   .order("points", { ascending: false })
      //   .order("speed_points", { ascending: true });

      let { data } = await client.rpc("get_winners");

      const results = data!.map((item: any) => ({
        id: item.author_id,
        main_info: item.main_info[0] ?? {},
        points:
          item.points +
          item.chat_points +
          item.cloud_points +
          item.poll_points +
          item.question_points,
        speed_points: item.speed_points,
        info: {
          points: item.points,
          chat_points: item.chat_points,
          cloud_points: item.cloud_points,
          poll_points: item.poll_points,
          question_points: item.question_points,
        },
      }));

      console.log(results);

      results.sort((a: any, b: any) => {
        if (a.points > b.points) {
          return -1;
        }
        if (a.points < b.points) {
          return 1;
        }
        if (a.speed_points < b.speed_points) {
          return -1;
        }
        if (a.speed_points > b.speed_points) {
          return 1;
        }
        return 0;
      });

      // console.log(
      //   results
      //     .map(
      //       (a, index) =>
      //         `${index + 1}. ${a.main_info.fname} (${a.main_info.lname}, ${
      //           a.main_info.phone
      //         }) — ${a.points}`
      //     )
      //     .join("\n")
      // );

      planItem.action = "winners";
      await client
        .from("rooms")
        .update({ poll: { ...planItem, results: results.slice(0, 50) } })
        .eq("id", ROOM_ID);
    },
    [client]
  );

  const copyWinners = useCallback(async () => {
    if (!client) {
      return;
    }

    // Quiz results

    // const { data } = await client
    //   .from("users")
    //   .select("id, points, speed_points, main_info")
    //   .order("points", { ascending: false })
    //   .order("speed_points", { ascending: true });

    let { data } = await client.rpc("get_winners");

    const results = data!.map((item: any) => ({
      id: item.author_id,
      main_info: item.main_info[0] ?? {},
      points:
        item.points +
        item.chat_points +
        item.cloud_points +
        item.poll_points +
        item.question_points,
      speed_points: item.speed_points,
      info: {
        points: item.points,
        chat_points: item.chat_points,
        cloud_points: item.cloud_points,
        poll_points: item.poll_points,
        question_points: item.question_points,
      },
    }));

    console.log(results);

    results.sort((a: any, b: any) => {
      if (a.points > b.points) {
        return -1;
      }
      if (a.points < b.points) {
        return 1;
      }
      if (a.speed_points < b.speed_points) {
        return -1;
      }
      if (a.speed_points > b.speed_points) {
        return 1;
      }
      return 0;
    });

    const copyString = results
      .map(
        (a, index) =>
          `${index + 1}. ${a.main_info.fname} ${a.main_info.lname} (${
            a.main_info.phone
          }, ${a.main_info.comment}) — ${a.points}`
      )
      .join("\n");

    setWinnersExport(copyString);
  }, [client]);

  const setPlanItem = useCallback(
    async (
      planItem: PlanItem,
      action: "start" | "stop" | "prepare" = "start"
    ) => {
      if (!client) {
        return;
      }
      planItem.action = action;
      setCurrentItem(planItem);

      await client
        .from("rooms")
        .update({
          poll: { ...planItem, results: planItem.type === "race" ? [] : {} },
        })
        .eq("id", ROOM_ID);
    },
    [client]
  );

  const getRaceWinners = useCallback(
    async (planItem: PlanItem) => {
      if (!client) {
        return;
      }

      const { data } = await client
        .from("race_winners")
        .select("author_id(*)")
        .eq("race_id", planItem.id)
        .order("created_at", { ascending: true })
        .limit(50);

      const pointsForRace = 300;
      const pointDecrementor = 30;

      const results = (data ?? []).map((item: any, index) => ({
        id: item.author_id.id,
        main_info: item.author_id.main_info ?? {},
        points: Math.max(0, pointsForRace - pointDecrementor * index),
      }));

      planItem.action = "winners";
      await client
        .from("rooms")
        .update({
          poll: {
            ...planItem,
            results,
          },
        })
        .eq("id", ROOM_ID);
    },
    [client]
  );

  const setPointsForRaceWinners = useCallback(
    async (planItem: PlanItem) => {
      if (!client) {
        return;
      }

      if (
        !window.confirm(
          'Начислить баллы за гонку? Если уже начисляли, то нажмите "Отмена"'
        )
      ) {
        return;
      }

      const { data } = await client
        .from("race_winners")
        .select("author_id(*)")
        .eq("race_id", planItem.id)
        .order("created_at", { ascending: true })
        .limit(10);

      const pointsForRace = 300;
      const pointDecrementor = 30;

      (data ?? []).forEach(async (item: any, index) => {
        const newPointsForUser =
          item.author_id.points + (pointsForRace - pointDecrementor * index);
        await client
          .from("users")
          .update({
            points: newPointsForUser,
          })
          .eq("id", item.author_id.id);
      });

      alert("Баллы за заезд начислены");
    },
    [client]
  );

  const refreshRaceResults = useCallback(
    async (planItem: PlanItem) => {
      if (!client || !planItem) {
        return;
      }
      setCurrentItem(planItem);

      // Get results
      let { data } = await client
        .from("poll")
        .select("author_id(id, main_info), answer")
        .eq("poll_id", planItem.id);

      const results: Racer[] = [];
      (data ?? []).forEach((row: any) => {
        results.push({
          author_id: row.author_id.id,
          author_photo: row.author_id.main_info.photo,
          author_name: row.author_id.main_info.fname,
          clicks: row.answer,
        });
      });

      await client
        .from("rooms")
        .update({ poll: { ...planItem, results } })
        .eq("id", ROOM_ID);
    },
    [client]
  );

  const refreshResults = useCallback(
    async (planItem: PlanItem) => {
      if (!client || !planItem) {
        return;
      }
      setCurrentItem(planItem);

      // Get results
      let { data } = await client.rpc("get_results", {
        request_id: planItem.id,
      });

      const results: Record<string, number> = {};
      data?.forEach((row: any) => {
        results[row.answer] = row.count;
      });

      await client
        .from("rooms")
        .update({ poll: { ...planItem, results } })
        .eq("id", ROOM_ID);
    },
    [client]
  );

  const sendPlanItemToChat = useCallback(
    async (planItem: PlanItem) => {
      if (!client || !user) {
        return;
      }
      // Get results
      let { data } = await client.rpc("get_results", {
        request_id: planItem.id,
      });

      const results: Record<string, number> = {};
      data?.forEach((row: any) => {
        results[row.answer] = row.count;
      });

      const newMessage = {
        author_id: user.id,
        author_name: "::system_poll::",
        author_photo: "::system_poll::",
        message: JSON.stringify({ ...planItem, results }),
      };
      await client.from("chat").insert(newMessage);
      clearPoll();
    },
    [clearPoll, client, user]
  );

  const setLink = useCallback(async () => {
    if (url.length === 0 || urlLabel.length === 0) {
      return;
    }

    const { data, error } = await client!
      .from("rooms")
      .select()
      .eq("id", ROOM_ID)
      .single();

    if (!data || error) {
      return;
    }

    await client!
      .from("rooms")
      .update({
        state: {
          ...data.state,
          chat_link: {
            url,
            label: urlLabel,
          },
        },
      })
      .eq("id", ROOM_ID);
  }, [url, urlLabel, client]);

  const clearLink = useCallback(async () => {
    const { data, error } = await client!
      .from("rooms")
      .select()
      .eq("id", ROOM_ID)
      .single();

    if (!data || error) {
      return;
    }

    await client!
      .from("rooms")
      .update({ state: { ...data.state, chat_link: undefined } })
      .eq("id", ROOM_ID);
  }, [client]);

  const showTimer = async () => {
    const { data, error } = await client!
      .from("rooms")
      .select()
      .eq("id", ROOM_ID)
      .single();

    if (!data || error) {
      return;
    }

    // const newTimerDate = addHours(new Date(), 1);

    await client!
      .from("rooms")
      .update({ state: { ...data.state, timer: 1702627200000 } })
      .eq("id", ROOM_ID);
  };
  const hideTimer = async () => {
    const { data, error } = await client!
      .from("rooms")
      .select()
      .eq("id", ROOM_ID)
      .single();

    if (!data || error) {
      return;
    }

    await client!
      .from("rooms")
      .update({ state: { ...data.state, timer: undefined } })
      .eq("id", ROOM_ID);
  };

  const showShop = async () => {
    const { data, error } = await client!
      .from("rooms")
      .select()
      .eq("id", ROOM_ID)
      .single();

    if (!data || error) {
      return;
    }

    if (!client) {
      return;
    }

    // First 10 winners can't buy anything
    let { data: winnersData } = await client.rpc("get_winners");

    const results = winnersData!.map((item: any) => ({
      id: item.author_id,
      main_info: item.main_info[0] ?? {},
      points:
        item.points +
        item.chat_points +
        item.cloud_points +
        item.question_points,
      speed_points: item.speed_points,
    }));

    results.sort((a: any, b: any) => {
      if (a.points > b.points) {
        return -1;
      }
      if (a.points < b.points) {
        return 1;
      }
      if (a.speed_points < b.speed_points) {
        return -1;
      }
      if (a.speed_points > b.speed_points) {
        return 1;
      }
      return 0;
    });

    await client!
      .from("rooms")
      .update({
        state: {
          ...data.state,
          shop: true,
          shop_blacklist: results.slice(0, 10),
        },
      })
      .eq("id", ROOM_ID);
  };
  const hideShop = async () => {
    const { data, error } = await client!
      .from("rooms")
      .select()
      .eq("id", ROOM_ID)
      .single();

    if (!data || error) {
      return;
    }

    await client!
      .from("rooms")
      .update({ state: { ...data.state, shop: false, shop_blacklist: [] } })
      .eq("id", ROOM_ID);
  };

  return (
    <>
      <div
        style={{ maxHeight: 350 }}
        className="col-span-4 lg:col-span-12 text-gray-900 flex flex-col py-4 px-0 rounded-xl bg-white overflow-auto"
      >
        <h2 className="font-semibold text-lg px-4 pb-2">Управление</h2>
        <hr className="mb-2" />
        <div className="flex flex-col lg:flex-row lg:space-x-2 space-y-2 w-full px-4 pb-3">
          <div className="flex space-x-2 items-center">
            <Input
              dark
              type="text"
              name="url"
              label="URL ссылки"
              value={url}
              onChange={setUrl}
            />
            <Input
              dark
              type="text"
              name="text"
              label="Текст ссылки"
              value={urlLabel}
              onChange={setUrlLabel}
            />
            <Button title="Установить" onClick={setLink} white small />
            <Button title="Сбросить" onClick={clearLink} white small />

            <Button title="Победители" onClick={copyWinners} white small />
          </div>
        </div>
        <div className="flex space-x-4 border-b border-t py-1">
          <div
            className={`py-2 px-4 flex flex-row space-x-2 w-full items-center`}
          >
            <p className="text-sm mr-auto">Таймер</p>
            <button
              onClick={() => showTimer()}
              className="ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
            >
              <Eye className="w-4" />
            </button>
            <button
              onClick={() => hideTimer()}
              className="ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
            >
              <EyeOff className="w-4" />
            </button>
          </div>
          <div className="h-full bg-gray-400 w-px"></div>
          <div
            className={`py-2 px-4 flex flex-row space-x-2 w-full items-center`}
          >
            <p className="text-sm mr-auto">Магазин</p>
            <button
              onClick={() => showShop()}
              className="ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
            >
              <Eye className="w-4" />
            </button>
            <button
              onClick={() => hideShop()}
              className="ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
            >
              <EyeOff className="w-4" />
            </button>
          </div>
          <div className="h-full bg-gray-400 w-px"></div>
          <div
            className={`py-2 px-4 flex flex-row space-x-2 w-full items-center`}
          >
            <p className="text-sm w-full">Оставить чат</p>
            <button
              onClick={() => clearPoll()}
              className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
            >
              <MessageCircle className="w-4" />
            </button>
          </div>
        </div>
        {PLAN.map((planItem) => {
          if (planItem.type === "quiz") {
            return (
              <div
                key={planItem.id}
                className={`${
                  currentItem?.id === planItem.id ? "bg-yellow-100" : ""
                } py-2 px-4 flex flex-row space-x-2 w-full items-center`}
              >
                <p className="text-xs">#{planItem.id}</p>
                <p className="text-sm w-full mr-auto">{planItem.name}</p>
                <button
                  onClick={() => setPlanItem(planItem)}
                  className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                >
                  <Play className="w-4" />
                </button>
                <button
                  onClick={() => setPlanItem(planItem, "stop")}
                  className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                >
                  <Square className="w-4" />
                </button>
                <button
                  onClick={() => getWinners(planItem)}
                  className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                >
                  <BarChart2 className="w-4" />
                </button>
              </div>
            );
          }
          if (planItem.type === "race") {
            return (
              <div
                key={planItem.id}
                className={`${
                  currentItem?.id === planItem.id ? "bg-yellow-100" : ""
                } py-2 px-4 flex flex-row space-x-2 w-full items-center`}
              >
                <p className="text-xs">#{planItem.id}</p>
                <p className="text-sm w-full mr-auto">{planItem.name}</p>
                <button
                  onClick={() => setPlanItem(planItem, "prepare")}
                  className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                >
                  <Loader className="w-4" />
                </button>
                <button
                  onClick={() => setPlanItem(planItem)}
                  className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                >
                  <Play className="w-4" />
                </button>
                <button
                  onClick={() => refreshRaceResults(planItem)}
                  className="flex-shrink-0 w-8 h-8 rounded-md border flex items-center justify-center"
                >
                  <RefreshCcw className="w-4" />
                </button>
                <button
                  onClick={() => getRaceWinners(planItem)}
                  className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                >
                  <BarChart2 className="w-4" />
                </button>
                <button
                  onClick={() => setPointsForRaceWinners(planItem)}
                  className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                >
                  <DollarSign className="w-4" />
                </button>
              </div>
            );
          }

          return (
            <div
              key={planItem.id}
              className={`${
                currentItem?.id === planItem.id ? "bg-yellow-100" : ""
              } py-2 px-4 flex flex-row space-x-2 w-full items-center`}
            >
              <p className="text-xs">#{planItem.id}</p>
              <p className="text-sm w-full mr-auto">{planItem.name}</p>
              <button
                onClick={() => setPlanItem(planItem)}
                className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
              >
                <Play className="w-4" />
              </button>
              <button
                onClick={() => refreshResults(planItem)}
                className="flex-shrink-0 w-8 h-8 rounded-md border flex items-center justify-center"
              >
                <RefreshCcw className="w-4" />
              </button>

              <button
                onClick={() => sendPlanItemToChat(planItem)}
                className="flex-shrink-0 w-8 h-8 rounded-md border flex items-center justify-center"
              >
                <MessageCircle className="w-4" />
              </button>
            </div>
          );
        })}
      </div>

      {winnersExport.length > 0 && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center ">
          <div className="bg-white rounded-xl p-4 text-black w-screen-md w-full max-w-screen-md">
            <textarea
              className="w-full h-64 bg-slate-100 p-4 text-sm mb-4"
              value={winnersExport}
              readOnly
            ></textarea>
            <Button
              title="Закрыть"
              onClick={() => setWinnersExport("")}
              white
              small
            />
          </div>
        </div>
      )}
    </>
  );
}

const MemoizedAdminPanel = memo(AdminPanel);
export default MemoizedAdminPanel;
