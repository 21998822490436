import React, { memo } from "react";
import { Lock } from "react-feather";

interface InputProps {
  dark?: boolean;
  name: string;
  label: string;
  value: string;
  type: string;
  max?: number;
  disabled?: boolean;
  onChange: (newVal: string) => void;
  className?: string;
  placeholder?: string;
}

function Input({
  name,
  label,
  value,
  max,
  onChange,
  type,
  dark,
  disabled,
  placeholder,
  className,
}: InputProps) {
  const onChangeValue = (val: string) => {
    switch (name) {
      case "instagram":
        val = val
          .replace(/((https?:\/\/)?(www\.)?instagram\.com\/)?@?/gi, "")
          .replace(/\?.+/gi, "")
          .toLowerCase()
          .replace(/[^a-z._0-9]/gi, "")
          .slice(0, 30);
        break;

      case "ticket":
        val = val.replace(/[^0-9]/gi, "").slice(0, 10);
        break;

      case "telegram":
        val = val
          .replace(/((https?:\/\/)?(www\.)?t\.me\/)?@?/gi, "")
          .replace(/\?.+/gi, "")
          .toLowerCase()
          .replace(/[^a-z._0-9]/gi, "")
          .slice(0, 40);
        break;

      case "phone":
      case "comment":
        val = val.replace(/[^0-9+]/gi, "");
        if (val.length > 12) val = val.slice(0, 12);
        break;

      case "work_id":
        val = val.replace(/[^0-9]/gi, "");
        break;

      case "email":
        val = val.toLowerCase().replace(/[^\w\d._@%+-]/gi, "");
        break;
    }

    onChange(val);
  };

  const isTextarea = type === "textarea";

  return (
    <div
      className={`${disabled ? "opacity-60" : ""} ${
        className ? className : ""
      } relative border ${
        dark
          ? "border-gray-400/40 focus-within:ring-secondary focus-within:border-secondary"
          : "border-white/30 focus-within:ring-white focus-within:border-white"
      } px-3 py-2 focus-within:ring-1 rounded-none`}
    >
      <label
        htmlFor="name"
        className={`block italic font-medium text-xs ${
          isTextarea ? "mr-12" : ""
        } ${dark ? "text-gray-600" : "text-white"}`}
      >
        {label}
      </label>
      {isTextarea ? (
        <textarea
          placeholder={placeholder}
          value={value}
          rows={4}
          name={name}
          disabled={disabled}
          onChange={(e) => onChangeValue(e.target.value)}
          className={`${
            dark ? "text-gray-900" : "text-white"
          } appearance-none bg-transparent block w-full border-0 p-0 placeholder-gray-400 outline-none focus:ring-0 sm:text-sm`}
        />
      ) : (
        <input
          placeholder={placeholder}
          value={value}
          type={type}
          name={name}
          disabled={disabled}
          onChange={(e) => onChangeValue(e.target.value)}
          className={`bg-transparent block w-full border-0 p-0 ${
            dark ? "text-gray-900" : "text-white"
          } placeholder-gray-400 outline-none focus:ring-0 sm:text-sm`}
        />
      )}
      {disabled && (
        <Lock className="text-gray-900 absolute right-3 w-5 bottom-4" />
      )}
      {max && (
        <div className="absolute right-2 top-2 text-xs text-gray-400">
          {value.length}/{max}
        </div>
      )}
    </div>
  );
}

const MemoizedInput = memo(Input);
export default MemoizedInput;
