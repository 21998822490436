import { memo, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Eye, EyeOff, Gift, HelpCircle } from "react-feather";
import { User } from "src/App";
import { ADMINS, MODERATORS } from "src/shared/config";
import star from "src/assets/icons/star.svg";
import heart from "src/assets/icons/heart.svg";

import { Message } from "..";
import { DIRTY_WORDS1, STICKERS } from "../config";

const POINTS_FOR_MESSAGE = 10;

function ChatMessage({
  message: m,
  user,
  onHide = () => {},
  onBan = () => {},
  onSetQuestion = () => {},
  onSetPoints = () => {},
  inBlacklist = false,
  onOpenUser,
  allowLinks,
}: {
  message: Message;
  user: User;
  onHide?: (hide: boolean) => void;
  onSetQuestion?: (is_question: boolean) => void;
  onSetPoints?: (points: number) => void;
  onBan?: () => void;
  inBlacklist?: boolean;
  onOpenUser: () => void;
  allowLinks?: boolean;
}) {
  const messageRef = useRef<HTMLDivElement>(null);
  const [messageWidth, setMessageWidth] = useState(187);

  useEffect(() => {
    // Fix for author name truncate
    if (messageRef.current) {
      const newWidth = Math.max(messageRef.current?.offsetWidth - 128, 187);
      setMessageWidth(newWidth);
    }
  }, []);

  const isMineMessage = m.author_id === user.id;
  const isAdmin = ADMINS.includes(user.id);
  const isModerator = MODERATORS.includes(user.id);

  const isSticker = STICKERS.includes(m.message.replace(/:/g, ""));
  const isCustomSticker = m.message.includes("custom_");
  const isVip = m.author_type === "vip";
  const isTester = m.author_type === "tester";

  let message = m.message;

  const isHighlighted = MODERATORS.includes(m.author_id);

  const wrapperClasses = ["bg-gray-200 flex w-full p-3 pt-0"];
  const photoClasses = [
    "cursor-default relative flex-shrink-0 w-8 h-8 rounded-full",
  ];
  const textClasses = [
    "bg-white p-2 px-3 text-sm rounded-xl relative break-words",
  ];

  if (isHighlighted) {
    // message = m.message.slice(3);
    photoClasses.push("ring-1 ring-bg");
    textClasses.push("border border-bg shadow-lg shadow-bg/30");
  }

  if (isMineMessage) {
    wrapperClasses.push("flex-row-reverse");
    photoClasses.push("ml-2");
    textClasses.push("rounded-tr-none");
  } else {
    photoClasses.push("mr-2");
    textClasses.push("rounded-tl-none");
  }
  if (m.tmp && isAdmin) {
    wrapperClasses.push("opacity-50");
  }
  if (m.hidden && (isAdmin || isModerator)) {
    wrapperClasses.push("opacity-50");
    // textClasses.push("truncate text-ellipsis");
  }

  if (m.hidden && !isAdmin && !isMineMessage && !isModerator) {
    return null;
  }

  const hasLinks = allowLinks && message.match(/https?:\/\/[^\s]+/g);
  if (hasLinks) {
    message = message.replace(/(https?:\/\/[^\s]+)/g, "[$1]($1)");
  }
  const needToShowMarkdown = ADMINS.includes(m.author_id) || hasLinks;

  return (
    <div key={m.id} ref={messageRef} className={wrapperClasses.join(" ")}>
      <div className="flex flex-col">
        <button onClick={() => {}} className={photoClasses.join(" ")}>
          <div
            className="bg-cover bg-center w-8 h-8 rounded-full"
            style={{ backgroundImage: `url(${m.author_photo})` }}
          />
          {isHighlighted && (
            <img
              className="w-3 h-3 absolute -right-0.5 -bottom-0.5 rounded-full ring-2 ring-white"
              src={star}
              alt="VIP"
            />
          )}
          {isTester && (
            <img
              className="w-2.5 h-2.5 absolute -right-0.5 -bottom-0.5 rounded-full ring-2 ring-white"
              src={heart}
              alt="Tester"
            />
          )}
        </button>
        {/* {isModerator && (
          <button
            onClick={onBan}
            className="w-6 h-6 mt-1 rounded-md border border-gray-300 flex items-center justify-center"
          >
            {inBlacklist ? (
              <Slash className="w-3 text-red-500" />
            ) : (
              <Slash className="w-3 text-gray-500" />
            )}
          </button>
        )} */}
      </div>
      {isSticker ? (
        <img
          src={`/images/stickers/${message.replace(/:/g, "")}.png`}
          alt={message}
          className={isCustomSticker ? "w-32 h-32" : "w-20 h-20"}
        />
      ) : (
        <div
          style={{ maxWidth: messageWidth }}
          className={textClasses.join(" ")}
        >
          <p className="truncate w-full text-xs uppercase tracking-wide italic font-semibold text-primary">
            {m.author_name}
          </p>
          {needToShowMarkdown ? (
            <ReactMarkdown
              components={{
                a: (props) => (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 underline underline-offset-1"
                    {...props}
                  />
                ),
                img: (props) => (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img className="w-full rounded-lg mb-1 mt-2" {...props} />
                ),
              }}
            >
              {message}
            </ReactMarkdown>
          ) : (
            message
          )}
        </div>
      )}

      {isModerator && (
        <div className="flex flex-wrap mx-2 mb-auto">
          <button
            onClick={() => onHide(!m.hidden)}
            className="w-6 h-6 rounded-md border border-gray-300 flex items-center justify-center mr-1 mb-1"
          >
            {m.hidden ? (
              <Eye className="w-3 text-gray-500" />
            ) : (
              <EyeOff className="w-3 text-gray-500" />
            )}
          </button>

          <button
            onClick={() => onSetQuestion(!m.is_question)}
            className="w-6 h-6 rounded-md border border-gray-300 flex items-center justify-center mr-1 mb-1"
          >
            {m.is_question ? (
              <HelpCircle className="w-3 text-pink-500" />
            ) : (
              <HelpCircle className="w-3 text-gray-500" />
            )}
          </button>
          <div className="w-full">
            <button
              onClick={() => onSetPoints(m.points > 0 ? 0 : POINTS_FOR_MESSAGE)}
              className="w-6 h-6 rounded-md border border-gray-300 flex items-center justify-center"
            >
              {m.points > 0 ? (
                <Gift className="w-3 text-pink-500" />
              ) : (
                <Gift className="w-3 text-gray-500" />
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const MemoizedChatMessage = memo(ChatMessage);
export default MemoizedChatMessage;
