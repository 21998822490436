import { memo, useContext, useEffect, useState } from "react";
import { SPEAKERS, SPEAKERS_2 } from "src/features/Schedule/config";
import { AppContext } from "src/shared/app-context";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ADMINS, ROOM_ID } from "src/shared/config";
import { Play, RefreshCw } from "react-feather";

import arrow from "src/assets/client/arrow.jpeg";

export interface ScheduleItem {
  id: number;
  time?: string;
  speakers: {
    speaker_name?: string;
    speaker_photo?: string;
    title?: string;
    job?: string;
    instagram?: string;
    telegram?: string;
  }[];
  title: string;
  subtitle?: string;
  highlights: string[];
  gift: {
    title: string;
    url: string;
  }[];
}

function ScheduleScreen({
  state,
}: {
  state: { item: number; speaker: string | null } | undefined;
}) {
  const analytics = getAnalytics();
  const { user, client } = useContext(AppContext);
  const [showOld, setShowOld] = useState(true);
  const [tab, showTab] = useState("30.01");

  const [maxHeight, setMaxHeight] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        const playerWrap = document.getElementById("player_wrap")!;
        setMaxHeight(playerWrap.clientHeight);
      } else {
        setMaxHeight(0);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    logEvent(analytics, "schedule");
  }, [analytics]);

  if (!user) {
    throw new Error("Empty user");
  }

  const setScheduleItem = async (item: number, speaker: string | null) => {
    const { data, error } = await client!
      .from("rooms")
      .select()
      .eq("id", ROOM_ID)
      .single();

    if (!data || error) {
      return;
    }

    await client!
      .from("rooms")
      .update({
        state: {
          ...data.state,
          schedule: {
            item,
            speaker,
          },
        },
      })
      .eq("id", ROOM_ID);
  };

  const currentItem = state?.item ?? 0;
  const currentSpeaker = state?.speaker ?? null;

  // if (!user || !user.id) {
  //   return null;
  // }

  const showBlank = false;

  if (showBlank) {
    return (
      <div
        style={{ maxHeight: maxHeight > 0 ? maxHeight : undefined }}
        className="relative overflow-hidden h-full flex aspect-[3/4] md:aspect-square w-full flex-col space-y-2 bg-white text-gray-900"
      >
        <div className="hidden flex">
          {["30.01", "01.02"].map((day) => {
            return (
              <button
                key={day}
                onClick={() => showTab(day)}
                className={`flex-1 p-2 text-center text-sm font-semibold ${
                  tab === day ? "bg-white" : "bg-gray-200"
                }`}
              >
                {day}
              </button>
            );
          })}
        </div>
        <div className="flex flex-col w-full h-full items-center justify-center">
          <img
            src="https://thumb.tildacdn.com/tild6566-6534-4065-b561-303733336335/-/resize/96x/-/format/webp/fit_cons.jpg"
            alt=""
            className="w-16 h-16 mb-8"
          />
          <h2 className="text-center text-base text-primary">
            программа трансляции
            <br />
            будет доступна позже
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: maxHeight > 0 ? maxHeight : undefined }}
      className="relative overflow-hidden h-full flex aspect-[3/4] md:aspect-square w-full flex-col space-y-2 bg-white text-gray-900"
    >
      <div className="hidden flex">
        {["30.01", "01.02"].map((day) => {
          return (
            <button
              key={day}
              onClick={() => showTab(day)}
              className={`flex-1 p-2 text-center text-sm font-semibold ${
                tab === day ? "bg-white" : "bg-gray-200"
              }`}
            >
              {day}
            </button>
          );
        })}
      </div>
      {/* <h2 className="flex w-full text-sm uppercase italic font-semibold justify-center pt-2 text-primary tracking-wide pb-4 border-b">
        Программа трансляции
      </h2> */}
      <div className="flex flex-col space-y-4 h-full w-full overflow-auto px-4 pb-6 pt-2">
        {/* {currentItem > 1 && !showOld && (
          <button
            onClick={() => setShowOld(true)}
            className="bg-white p-4 border flex flex-col"
          >
            <p className="text-sm text-secondary font-semibold text-center w-full">
              Показать прошедшее
            </p>
          </button>
        )} */}
        {ADMINS.includes(user.id) && (
          <div className="flex w-full bg-slate-50 p-2">
            <p className="font-light text-xl lowercase">Сбросить выбор</p>
            <button
              onClick={() => setScheduleItem(0, null)}
              className="flex-shrink-0 ml-auto w-8 h-8 border flex items-center justify-center"
            >
              <RefreshCw className="w-4" />
            </button>
          </div>
        )}
        {(tab === "30.01" ? SPEAKERS : SPEAKERS_2).map((item) => {
          if (item.id < currentItem && !showOld) {
            return null;
          }
          return (
            <div
              key={item.id}
              className={`flex flex-col ${
                item.id === 13 ? "" : "border-b"
              } pb-4`}
            >
              <div className="w-full flex space-x-2 items-center">
                <div className="flex w-full items-center relative">
                  {/* <img
                    src={arrow}
                    className={`${
                      currentItem === item.id
                        ? "opacity-100 scale-100"
                        : "opacity-0 scale-0"
                    } transition duration-700 w-6 h-6 absolute mt-0.5`}
                    alt="arrow"
                  /> */}
                  <div
                    className={`flex flex-col w-full transition transform duration-700`}
                  >
                    <div className="flex">
                      {currentItem === item.id ? (
                        <p className="px-1 text-center rounded-full flex-shrink-0 text-sm text-white bg-bg mb-auto italic font-semibold tracking-wide w-12 mr-2">
                          {item.time}
                        </p>
                      ) : (
                        <p className="flex-shrink-0 text-sm text-primary italic font-semibold tracking-wide w-14">
                          {item.time}
                        </p>
                      )}

                      <h3
                        className={`${
                          currentItem === item.id ? "text-primary" : ""
                        } text-sm font-bold italic`}
                      >
                        {item.title}
                      </h3>
                    </div>
                    {!!item.subtitle && (
                      <h4 className="text-xs opacity-80 italic ml-14 mt-2">
                        {item.subtitle}
                      </h4>
                    )}
                  </div>

                  {item.highlights.length > 0 && (
                    <p className="text-xs text-gray-800">
                      {item.highlights.join(", ")}
                    </p>
                  )}
                </div>
                {ADMINS.includes(user.id) && (
                  <button
                    onClick={() => setScheduleItem(item.id, null)}
                    className="flex-shrink-0 ml-auto w-8 h-8 border flex items-center justify-center"
                  >
                    <Play className="w-4" />
                  </button>
                )}
              </div>
              {item.speakers.map((speaker) => (
                <div
                  key={speaker.speaker_name ?? "default" + item.id}
                  className="flex mt-4 ml-14"
                >
                  {speaker.speaker_photo && (
                    <img
                      src={speaker.speaker_photo}
                      alt={speaker.speaker_name}
                      className="w-12 h-12 rounded-bl-2xl mr-2 flex-shrink-0"
                    />
                  )}
                  <div className="flex flex-col">
                    {speaker.speaker_name && (
                      <p className="text-xs italic tracking-wide mt-1 font-semibold">
                        {speaker.speaker_name}
                      </p>
                    )}
                    {speaker.job && (
                      <p className="text-xs mt-1">{speaker.job}</p>
                    )}
                  </div>
                </div>

                // <>
                //   <hr className="my-2" />
                //   <div className="flex w-full space-x-2 items-center">
                //     {speaker.speaker_photo && (
                //       <img
                //         src={speaker.speaker_photo}
                //         alt={speaker.speaker_name}
                //         className="w-12 h-12 rounded-full"
                //       />
                //     )}
                //     <div
                //       className={`${
                //         speaker.speaker_name === currentSpeaker
                //           ? "text-primary"
                //           : ""
                //       } flex flex-col w-full`}
                //     >
                //       {speaker.title && (
                //         <p className="text-sm font-semibold mb-2 text-blue-500">
                //           {speaker.title}
                //         </p>
                //       )}
                //       <h3 className="text-xs font-semibold mb-1 italic">
                //         {speaker.speaker_name}
                //       </h3>
                //       <p className="text-xs opacity-80 italic">{speaker.job}</p>
                //     </div>
                //     {ADMINS.includes(user.id) && (
                //       <button
                //         onClick={() =>
                //           setScheduleItem(item.id, speaker.speaker_name!)
                //         }
                //         className="flex-shrink-0 ml-auto w-8 h-8 border flex items-center justify-center"
                //       >
                //         <Play className="w-4" />
                //       </button>
                //     )}
                //   </div>
                // </>
              ))}
              {/* <div className="-ml-20 font-semibold shadow-xl shadow-primary/10 rounded-full py-1 text-sm absolute bottom-4 left-1/2 w-40 px-2 bg-primary text-white text-center">
                Connect to Zoom
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const MemoizedScheduleScreen = memo(ScheduleScreen);
export default MemoizedScheduleScreen;
